<template>
  <div
    class="embed-responsive embed-responsive-16by9"
    style="overflow: hidden; padding-top: 0%; position: relative"
  >
    <iframe
      :src="source_parameter + '?autoplay=1'"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {},
  computed: {
    source_parameter() {
      if (this.$store.state.currentRoom.source_parameter) {
        return this.$store.state.currentRoom.source_parameter;
      } else {
        return this.$store.state.currentSpace.source_parameter;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
