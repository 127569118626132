<template>
  <div class="RoomMainYoutube" :class="{ '--show': show }">
    <BModal
      ref="errorModal"
      title="Unable to load video"
      ok-only
      ok-title="Continue"
      @hidden="ended()"
    >
      <p>There was a problem loading the embedded video on this page.</p>
      <p style="line-height: 1; padding-left: 16px">
        <small style="color: grey">
          (Note: sometimes this problem is solved by clearing your browser
          cookies and site data, or by signing out of your google account on
          chrome)
        </small>
      </p>
      <p>Click below to watch it on youtube.</p>
      <p>
        <a :href="videoLink" target="_blank" class="btn btn-primary"
          >Watch on youtube</a
        >
      </p>
      <p>
        Then come back and click "continue" below to continue the experience
      </p>
    </BModal>

    <div class="embed-responsive embed-responsive-16by9">
      <div id="intro-video-iframe" class="embed-responsive-item" />
    </div>
    <button
      v-if="slowLoad && !playerReady"
      ref="continueButton"
      @click="ended"
      class="btn btn-light"
      style="display: block; margin: auto"
    >
      Continue to room ->
    </button>
  </div>
</template>

<script>
import getYouTubeID from "get-youtube-id";
import { BModal } from "bootstrap-vue";
import YouTubePlayer from "youtube-player";

export default {
  components: { BModal },
  props: {
    videoLink: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      player: null,
      events: {
        "-1": () => this.unstarted(),
        0: () => this.ended(),
        1: () => this.playing(),
        2: () => this.paused(),
        3: () => this.buffering(),
        5: () => this.videoCued(),
      },
      playerReady: false,
      readyHandler: null,
      errorHandler: null,
      stateChangeHandler: null,
      slowLoad: false,
      show: false,
      videoEnded: false,
    };
  },
  computed: {
    videoId() {
      return getYouTubeID(this.videoLink);
    },
  },
  mounted() {
    this.$store.commit("setBoolean", { prop: "loadingYoutube", value: true });

    // load the player and the api controls
    this.player = YouTubePlayer("intro-video-iframe", {
      videoId: this.videoId,
      playerVars: {
        rel: 0,
        showinfo: 0,
      },
    });

    // play video as soon as we can
    // 'playVideo' is queue until the player is ready to received API calls.
    // could also put it in the readyHandler and it would have the same effect.
    this.player.playVideo();

    // detect when the youtube api has finished loading
    // remove loading spinner
    // fade in the video after 1s
    this.readyHandler = this.player.on("ready", () => {
      // console.log("controls loaded");

      this.$store.commit("setBoolean", {
        prop: "loadingYoutube",
        value: false,
      });

      setTimeout(() => {
        this.show = true;
      }, 1000);

      this.playerReady = true;
    });

    this.errorHandler = this.player.on("error", (err) => {
      this.$refs.errorModal.show();
    });

    // detect when the video state had changed
    // call the associated function
    this.stateChangeHandler = this.player.on("stateChange", (event) => {
      // console.log("youtube event", event.data);
      const func = this.events[event.data];
      if (func) {
        func();
      }
    });

    // if the player hasn't loaded after 30s
    // show it anyway so user can play it manually
    // also show contunue button so they can go forward when the video has ended.
    setTimeout(() => {
      if (!this.playerReady) {
        this.slowLoad = true;
        this.show = true;
        this.$store.commit("setBoolean", {
          prop: "loadingYoutube",
          value: false,
        });
      }
    }, 30000);
  },
  beforeDestroy() {
    this.player.off(this.readyHandler);
    this.player.off(this.stateChangeHandler);
    this.player.off(this.errorHandler);
    this.$store.commit("setBoolean", { prop: "loadingYoutube", value: false });
  },
  methods: {
    unstarted() {
      // console.log("unstarted");
    },
    ended() {
      this.show = false;
      this.videoEnded = true;

      // console.log("ended");
      const videosWatched =
        JSON.parse(localStorage.getItem("videosWatched")) || [];
      videosWatched.push(this.videoLink);
      localStorage.setItem("videosWatched", JSON.stringify(videosWatched));

      this.$emit("finished", this.videoLink);
    },
    playing() {
      // console.log("playing");
    },
    paused() {
      // console.log("paused");
    },
    buffering() {
      // console.log("buffering");
    },
    videoCued() {
      // console.log("videoCued");
    },
  },
};
</script>

<style lang="scss" scoped>
.RoomMainYoutube {
  flex-grow: 1;
  opacity: 0;
  transition: opacity 3s;
  pointer-events: none;
  &.--show {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>
