<template>
  <form class="card" @submit="submit">
    <div class="card-header">Its yours</div>
    <div class="card-body">
      <p>You have been awarded {{ earnedKey.name }}</p>
      <div v-if="!earnedKey.claimed" class="form-group">
        <label for="key_text" class="control-label">{{
          earnedKey.text_label
        }}</label>
        <input
          maxlength="30"
          id="key_text"
          class="form-control"
          name="key_text"
          required="true"
          v-model="key_text"
        />
      </div>
      <div v-else>
        {{ earnedKey.text }}
      </div>
      <button v-if="!earnedKey.claimed" class="btn btn-primary">
        Claim Key
      </button>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    earnedKey: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      key_text: "",
    };
  },
  computed: {
    ...mapState([
      "currentRoom",
      "currentUser",
      "navigationCollapsed",
      "loadingJitsi",
      "loadingYoutube",
      "currentSpace",
      "keys",
      "earnedKeys",
    ]),
  },
  methods: {
    keyText(key_id) {
      if (this.earnedKey.key_id == key_id) {
        return this.key_text;
      }
      if (!this.earnedKeys) {
        return "";
      }
      const key = this.earnedKeys.find((k) => {
        return k.key_id == key_id;
      });
      if (!key) {
        return "";
      }
      return key.text;
    },
    submit(e) {
      if (e) {
        e.preventDefault();
      }
      this.$store
        .dispatch("claimKey", {
          key_id: this.earnedKey.id,
          text: this.key_text,
        })
        .then(() => {
          // if user now has 4 keys (3 + the one they just claimed)
          // give access to astral plane
          console.log("user now has", this.earnedKeys);
          if (
            this.earnedKeys.filter((k) => {
              return k.claimed;
            }).length >=
            this.keys.length - 1
          ) {
            // update user name
            const name =
              `${this.keyText(2)}, ` +
              `the ${this.keyText(3)}, ` +
              `who will ${this.keyText(1)} ` +
              `without ${this.keyText(4)}`;
            this.$store
              .dispatch("updateUsername", name)
              .catch((err) => console.log(err));

            // give acccess
            this.$store
              .dispatch("accessAstralPlane", this.currentUser)
              .catch((err) => console.log(err));
          } else {
            this.$store
              .dispatch("teleport", this.currentSpace.default_room.room_id)
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  color: black;
  width: 100%;
}
</style>
