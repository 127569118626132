<template>
  <div
    class="embed-responsive embed-responsive-16by9"
    style="overflow: hidden; padding-top: 0%; position: relative"
  >
    <iframe
      src="https://player.castr.com/live_2146b110af4411eb8179630628895db1?onlyAudio=true"
      width="590"
      height="431"
      frameborder="0"
      scrolling="no"
      allow="autoplay"
      allowfullscreen
      webkitallowfullscreen
      mozallowfullscreen
      oallowfullscreen
      msallowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped></style>
