<template>
  <div
    class="embed-responsive embed-responsive-16by9"
    style="overflow: hidden; padding-top: 0%; position: relative"
  >
    <iframe
      class="embed-responsive-item"
      :src="
        'https://www.youtube-nocookie.com/embed/' +
        source_parameter +
        '?autoplay=1'
      "
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
export default {
  props: {},
  computed: {
    source_parameter() {
      if (this.$store.state.currentRoom.source_parameter) {
        return this.$store.state.currentRoom.source_parameter;
      } else {
        return this.$store.state.currentSpace.source_parameter;
      }
    },
  },};
</script>

<style lang="scss" scoped></style>
