<template>
  <article>
    <h3>Virtual forum during the COP26</h3>
    <br />
    <p>
      This forum will highlight the culture’s contribution to environmental
      transition and rally cultural and environmental figures around this
      important issue.
    </p>
    <p>
      How can culture be a stakeholder in this movement towards empowerment?
    </p>
    <br />
    <p>For best experience we suggest:<br /></p>
    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Get your computers ready to join
        <strong>(iPhone and iPad NOT supported) </strong>
      </li>
      <li>
        <i class="fa fa-chrome"></i> Please join from your Chrome browser.
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Here
        </a>
        's a link to install it if you don't have it.
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>
        Your browser may prompt you to give video and audio permissions. Simply
        click allow.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>
        Your browser may prompt you to give video and audio permissions. Simply
        click allow.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Turn your phones off and enjoy the
        event!
      </li>
    </ul>
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
