<template>
  <div class="RoomPanelRightSettings">
    <RoomNavigationButton
      :class="{ collapsed: !settingsExpanded }"
      class="SettingsButton"
      small
      @click="settingsExpanded = !settingsExpanded"
    >
      <h4><i class="fas fa-cog"></i>{{ $t("message.settings") }}</h4>
    </RoomNavigationButton>
    <BCollapse v-model="settingsExpanded">
      <div class="RoomPanelRightSettings_SectionContent">
        <div class="RoomPanelRightSettings_SectionContent_Text text-left">
          <BForm @submit="submitUsername">
            <BFormGroup
              class="BFormGroup"
              :label="(fields && fields.user_name_label) || ''"
              label-for="user_name_field"
            >
              <div class="d-flex flex-row">
                <BFormInput
                  id="user_name_field"
                  autocomplete="off"
                  v-model="formInfo.user_name_field"
                  :placeholder="
                    this.currentUser ? this.currentUser.user_name : ''
                  "
                  class="theme-form-text-area"
                />
                <p v-if="success.user_name_field">
                  {{ $t("message.success") }}
                </p>
                <BButton
                  class="theme-button-primary"
                  type="submit"
                  variant="dark"
                  >Ok</BButton
                >
              </div>
            </BFormGroup>
          </BForm>
          <BForm @submit="submitUserText" class="BForm">
            <BFormGroup
              class="BFormGroup"
              :label="(fields && fields.user_metadata_label) || ''"
              label-for="user_text_field"
            >
              <div class="d-flex flex-row">
                <BFormInput
                  id="user_text_field"
                  autocomplete="off"
                  v-model="formInfo.user_text_field"
                  :placeholder="` ${
                    this.currentUser ? this.currentUser.user_text : ''
                  }`"
                  class="theme-form-text-area"
                />
                <p v-if="success.user_text_field">
                  {{ $t("message.success") }}
                </p>
                <BButton
                  class="theme-button-primary"
                  type="submit"
                  variant="dark"
                  >Ok</BButton
                >
              </div>
            </BFormGroup>
          </BForm>
          <BForm @submit="submitRoomText">
            <BFormGroup
              class="BFormGroup"
              v-if="!is_lock_admin_only || currentUser.admin != 0"
              :label="$t('message.settings_room_tagline')"
              label-for="target_room_text"
            >
              <!-- was BFormTextarea -->
              <div class="d-flex flex-row">
                <BFormInput
                  id="target_room_text"
                  autocomplete="off"
                  v-model="formInfo.target_room_text"
                  :placeholder="
                    $t('message.settings_room_tagline') +
                    ' :' +
                    ` ${this.currentRoom ? this.currentRoom.room_text : ''}`
                  "
                  class="theme-form-text-area"
                />
                <p v-if="success.target_room_text">
                  {{ $t("message.success") }}
                </p>
                <BButton type="submit" variant="dark">Ok</BButton>
              </div>
            </BFormGroup>
          </BForm>
          <BForm @submit="lockRoom">
            <p v-if="success.lock_room">{{ $t("message.success") }}</p>
            <BButton
              v-if="!is_lock_admin_only || currentUser.admin != 0"
              type="submit"
              variant="dark"
            >
              {{
                is_room_locked
                  ? $t("message.settings_unlock_room")
                  : $t("message.settings_lock_room")
              }}
            </BButton>
            <p v-else></p>
          </BForm>
        </div>
      </div>
    </BCollapse>
    <!--
    <p>{{ $t("message.settings_text") }}</p>
    -->
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  BCollapse,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import RoomNavigationButton from "@/components/RoomNavigation/RoomNavigationButton.vue";

export default {
  components: {
    BCollapse,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    //    BFormTextarea,
    RoomNavigationButton,
  },
  props: {},
  data() {
    return {
      settingsExpanded: false,
      formInfo: {
        user_text_field: "",
        user_name_field: "",
        target_room_text: "",
      },
      success: {
        user_text_field: false,
        user_name_field: false,
        target_room_text: false,
        lock_room: false,
      },
    };
  },
  computed: {
    ...mapState(["currentRoom", "currentUser", "currentEvent"]),
    fields() {
      if (this.currentEvent?.event_page_fields) {
        return {
          ...this.default_fields,
          ...JSON.parse(this.currentEvent.event_page_fields),
        };
      }
      return this.default_fields;
    },
    is_room_locked() {
      // 1 and 3 = locked
      // 0 and 2 = unlocked
      return this.currentRoom.is_locked == 1 || this.currentRoom.is_locked == 3;
    },
    is_lock_admin_only() {
      // 0 and 1 = user accessible lock
      // 2 and 3 = lock accessible to admins only
      return this.currentRoom.is_locked == 2 || this.currentRoom.is_locked == 3;
    },
  },
  methods: {
    showSuccess(field) {
      this.success[field] = true;
      if (this.formInfo[field]) {
        this.formInfo[field] = "";
      }
      this.interval = setInterval(() => {
        this.success[field] = false;
      }, 10000);
    },
    submitUserText(e) {
      e.preventDefault();
      this.$store
        .dispatch("updateUserText", this.formInfo.user_text_field)
        .then(() => {
          this.showSuccess("user_text_field");
        });
    },
    submitUsername(e) {
      e.preventDefault();
      if (this.formInfo.user_name_field) {
        this.$store
          .dispatch("updateUsername", this.formInfo.user_name_field)
          .then(() => {
            this.showSuccess("user_name_field");
          });
      }
    },
    submitRoomText(e) {
      e.preventDefault();
      this.$store
        .dispatch("updateRoomText", this.formInfo.target_room_text)
        .then(() => {
          this.showSuccess("target_room_text");
        });
    },
    lockRoom(e) {
      //e.preventDefault();
      //if (this.is_lock_admin_only) {
      //  return;
      // }
      if (this.is_room_locked) {
        this.$store.dispatch("lockRoom", false).then(() => {
          this.showSuccess("lock_room");
        });
        return;
      }
      this.$store.dispatch("lockRoom", true).then(() => {
        this.showSuccess("lock_room");
      });
    },
  },
};
</script>

<!-- this flex is still not working -->
<style lang="scss" scoped>
.BButton {
  flex-grow: 1;
}

.RoomPanelRightSettings {
  &_SectionContent {
    max-height: 100vh;
    transition: all 0.3s;
    &_Text {
      padding: 16px 8px;
    }
  }
}

.SettingsButton {
  text-align: left;
}

.fa-cog {
  padding-right: 24px;
  opacity: 0.8;
}
</style>
