import { io } from "socket.io-client";
import { nodeserver } from "../../agora-conf.json";

// client
// create socket connection with server
const socket =
  process.env.NODE_ENV === "development"
    ? io(
        `${nodeserver.protocol}${nodeserver.host}${
          nodeserver.port ? `:${nodeserver.port}` : ""
        }`
      )
    : io();

export default socket;
