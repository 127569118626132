<template>
  <div class="RoomNavigation">



    <!-- Render all spaces -->
    <SpaceNavigationItem
      v-for="space in spaces.filter((s) => s.space_id != currentSpace.space_id)"
      :key="space.space_id"
      :space="space"
      :eventSpace="
        // event space with extra info about whether the space is hidden
        currentEvent.eventSpaces.find((s) => s.space_id === space.space_id)
      "
    />

    <!-- Render all rooms -->
    <div class="RoomNavigation_rooms">
      <BSpinner v-if="!roomsList.length" variant="light" />
      <div class="RoomNavigation_List">
        <RoomNavigationItem
          v-for="room in roomsListFinal"
          :key="room.room_id"
          :room="room"
          @userSettings="openUserSettings"
          @roomAdminSettings="roomAdminSettings"
        />
      </div>
    </div>

  </div>
</template>

<script>
import RoomNavigationItem from "@/components/RoomNavigation/RoomNavigationItem.vue";
import { BSpinner } from "bootstrap-vue";
import { mapState } from "vuex";
import SpaceNavigationItem from "@/components/RoomNavigation/SpaceNavigationItem.vue";

export default {
  components: {
    RoomNavigationItem,
    SpaceNavigationItem,
    BSpinner,
  },
  data() {
    return {
      interval: null,
    };
  },
  computed: {
    ...mapState([
      "roomsList",
      "currentRoom",
      "currentUser",
      "currentEvent",
      "currentSpace",
      "spaces",
    ]),
    roomsListFinal() {
      // filter out current room from list
      // filter out hidden rooms if user isn't an admin
      return this.roomsList.filter((r) => {
        return (
          r.room_id !== this.currentRoom.room_id &&
          (!r.hidden || this.currentUser.admin)
        );
      });
    },
  },
  methods: {
    openUserSettings(user) {
      this.$emit("openUserSettings", user);
    },
    roomAdminSettings(room) {
      this.$emit("roomAdminSettings", room);
    },
    enterSpace(space) {
      this.$store.dispatch("moveToSpace", space);
    },
  },
};
</script>

<style lang="scss" scoped>
.RoomNavigation {
  display: flex;
  flex-direction: column;
  &_List {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
  }
  &_SpaceButton {
    text-align: left;
    :hover {
      box-shadow: 2px 2px 10 black;
    }
  }
}
</style>
