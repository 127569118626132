import axios from "axios";
import { nodeserver } from "../../agora-conf.json";

const nodeApiUrl = `${nodeserver.protocol}${nodeserver.host}${
  nodeserver.port ? `:${nodeserver.port}` : ""
}${nodeserver.apidir}`;

const errorLog = (err) => {
  if (err.response) {
    if (err.response.data) {
      console.error(err.response.data);
      return;
    }
    console.error(err.response);
    return;
  }
  console.error(err);
};

const keyActions = {
  giveKeyToUser({}, { key_id, user_id, room_id }) {
    console.log("give key", key_id, user_id, room_id);
    return new Promise((resolve, reject) => {
      axios
        .post(`${nodeApiUrl}/earned_keys`, {
          user_id,
          key_id,
          room_id,
        })
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  claimKey({}, { key_id, text }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${nodeApiUrl}/earned_keys/${key_id}`, { text, claimed: true })
        .then(() => resolve())
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  getKeyOptions({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/keys`)
        .then((res) => {
          commit("setKeys", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  getUsersKeys({ state, commit }, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/earned_keys?user_id=${user_id}`)
        .then((res) => {
          // if it's for the current user
          // also set in the store
          if (state.currentUser.user_id === user_id) {
            commit("setEarnedKeys", res.data);
          }
          resolve(res.data);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
};

export default keyActions;
