<template>
  <article>
    <h2>NOUVLR</h2>
    <h3>Summer 2021 Management Meeting</h3>
    <br />
    <p>
      Nous sommes heureux de vous avoir donné ce rendez-vous virtuel. Ceci est
      un espace spécialement conçu pour échanger et connecter avec vous. Nous
      vous invitons à être actifs et ouverts aux discussions avec vos collègues
      lors de la rencontre et des moments de réseautage libre.
    </p>
    <p>Quelques consignes pour améliorer votre expérience:</p>
    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Il est fortement conseillé d’utiliser un
        ordinateur pour profiter au maximum de l’expérience. Les
        <strong>iPhones et iPads sont déconseillés, </strong> car certaines
        versions peuvent poser des problèmes avec le partage du microphone et de
        la caméra.
      </li>
      <li>
        <i class="fa fa-chrome"></i> L’utilisation du navigateur Google Chrome
        est recommandée. Vous pouvez cliquer
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          ici
        </a>
        pour l’installer, si vous ne l’avez pas.
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>Il faut autoriser la
        connexion vidéo et audio lorsque demandé.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Il est possible que
        l’audio devienne muet lorsque tul’on changes de salles ou de tables, il
        suffit simplement de le réactiver.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Nous vous invitons à éteindre votre
        téléphone et annuler les notifications de courriel pour profiter
        pleinement du moment que vous allez passer avec nous.
      </li>
    </ul>
    <br />
    <p>Bonne rencontre !</p>
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
