<template>
  <article>
    <h2>Bienvenue au rendez-vous VBGA.</h2>
    <br />
    <p>
      Nous sommes heureux que tu sois présent à l’évènement, BÂTIR ENSEMBLE. Au
      cours de la rencontre, tu auras l’occasion de découvrir le nouvel
      organigramme de la firme, d’échanger avec tes collègues et la direction et
      de célébrer avec nous.
    </p>
    <br />
    <p>
      Il s’agit de notre première expérience dans l’organisation d’un événement
      virtuel et nous avons à cœur, qu’elle soit pour toi, la plus agréable et
      optimale possible.
    </p>
    <strong
      >C’est pourquoi, nous t’invitons à lire très attentivement ce qui suit
      :</strong
    >
    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop" aria-hidden="true"></i> Il est essentiel que
        tu utilises un ordinateur pour te brancher à ta session car les iPhones
        et les iPads ne sont pas compatibles avec la plateforme. Si ce n’est pas
        possible pour toi, tu peux utiliser une tablette androïde.
      </li>
      <li>
        <i class="fa fa-chrome" aria-hidden="true"></i> L’utilisation des
        navigateurs Google Chrome ou Firefox est requise. Tu peux cliquer
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=fr"
          >ici</a
        >
        pour l’installer, si tu ne l’as pas.
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>Il faut absolument que tu
        autorises la connexion vidéo et audio. Il est possible qu’une demande
        d’autorisation te sois demandée plus d’une fois (exemple : après un
        changement de salle) : l’autoriser à chaque fois.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Il est possible que
        l’audio devienne muet lorsque tu changes de salles ou de tables, il
        suffit simplement de le réactiver.
      </li>
      <li>
        <i class="fas fa-phone-slash" aria-hidden="true"></i>Nous t’invitons à
        éteindre ton téléphone pour profiter pleinement du moment que tu
        passeras avec tes collègues.
      </li>
    </ul>
    <h3>Bon rendez-vous !</h3>
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
