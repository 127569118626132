<template>
  <header class="RoomHeader theme-header">
    <div id="container">
      <div class="left">
        <div v-if="currentEvent.white_label != 1">
          <a
            class="RoomHeader_Logo"
            href="https://hello.atypiclabs.com/gatherings"
            target="_blank"
          >
            <img src="/img/agora_logo.png" />
          </a>
        </div>
        <div v-else>
          <a
            @click="
              teleportSpecificRoom(
                currentEvent.room_id,
                currentEvent.initial_space
              )
            "
            :class="
              currentEvent.room_id == currentRoom.room_id
                ? 'RoomHeader_Logo_Unclickable'
                : 'RoomHeader_Logo'
            "
          >
            <img :src="`${$store.state.imgdir}/${currentEvent.logo}`" />
          </a>
        </div>
      </div>

      <div class="center justify-content-center">
        <!--help: the text of the url below works, but don't know how to plug it into the src (getting errors when I try)
        <img :src="logo_url" >
             {{$store.state.imgdir}}/{{currentEvent.logo }}
      -->
        <a
          v-if="currentEvent.logo && currentEvent.white_label != 1"
          class="RoomHeader_Logo"
          :href="`${currentEvent.website}`"
          target="_blank"
        >
          <img :src="`${$store.state.imgdir}/${currentEvent.logo}`" />
        </a>
      </div>

      <div class="right">
        <h2 class="d-none d-sm-block">{{ currentSpace.space_name }}</h2>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["currentEvent", "currentRoom", "currentSpace", "currentUser"]),
  },
  methods: {
    teleportSpecificRoom(room, space) {
      this.$store.dispatch("teleportUserToRoom", {
        user_id: this.currentUser.user_id,
        room_id: room,
        space_id: space,
      });
    },
    enterSpace(space) {
      this.$store.dispatch("moveToSpace", space);
    },
  },
};
</script>

<style lang="scss" scoped>
.RoomHeader {
  z-index: 5;
  width: 100%;
  min-height: 70px;
  &_Logo_Unclickable {
    img {
      max-height: 48px;
    }
  }
  &_Logo {
    img {
      max-height: 48px;
    }
      cursor: pointer;
    }
}

#container {
  padding: 12px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.left {
  flex: 2;
  display: flex;
  align-items: center;
  padding-bottom: 2px;

  //border: 1px dashed red;
}

.center {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  //border: 1px dashed red;
}

.right {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 7px;

  //border: 1px dashed red;
}
</style>
