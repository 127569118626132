<template>
  <article>
    <h2>Nationalfeiertag von Québec</h2>
    <h3>Virtueller Empfang</h3>
    <br />
    <p>
      In diesem Jubiläumsjahr der 50-jährigen Präsenz Québecs in Deutschland,
      der Schweiz und Österreich möchte die Vertretung der Regierung von Québec
      in München und Berlin gemeinsam mit Ihnen den Nationalfeiertag von Québec
      begehen.
    </p>
    <p>
      Da wir noch nicht zu einem physischen Empfang "wie früher" mit Ihnen
      zusammenkommen können, laden wir Sie bei einem virtuellen Empfang zum
      Networking 2.0 ein!
    </p>
    <p>Einige Richtlinien zur Nutzung:</p>

    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Es wird dringend empfohlen, einen
        Computer zu verwenden, um eine optimale Nutzung zu ermöglichen.
        <strong>iPhones und iPads werden nicht empfohlen</strong>, da einige
        Modelle Probleme mit der Mikrofon- und Kamerafreigabe haben können.
      </li>
      <li>
        <i class="fa fa-chrome"></i> Die Verwendung des GOOGLE CHROME-Browsers
        wird empfohlen. Sie können
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          hier
        </a>
        klicken, um ihn zu installieren
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>Die VIDEO- und
        AUDIO-Verbindung muss bei Anfrage autorisiert werden.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Falls der Ton beim
        Wechseln Ihres Standortes im Raum stumm geschaltet wird, können Sie
        diesen jederzeit wieder einschalten.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Wir laden Sie dazu ein, Ihr Telefon
        auszuschalten, um den Moment, den Sie mit uns verbringen werden, in
        vollen Zügen zu genießen.
      </li>
    </ul>
    <br />
    <p>Wir wünschen Ihnen eine gute Veranstaltung!</p>
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
