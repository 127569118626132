<template>
  <article>
    <h2>INDIE CAMPUS - COLOGNE powered by 4Players</h2>
    <h3>Retention & Monetization Summit</h3>
    <br />
    <p>
      Join us for two days packed with amazing talks, interesting roundtable
      discussions, and the chance to meet and connect with like-minded people
      from around the world.
    </p>

    <ul class="icon-list">
      <li><i class="fas fa-desktop"></i> Get your computers to join.</li>
      <li>
        <i class="fa fa-chrome"></i> Please join from your Chrome browser.
        Here’s a
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en"
          >link</a
        >
        to install in case you don’t use Chrome.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Your browser may
        prompt you to give video and audio permissions. Simply click allow.
      </li>
    </ul>
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 32px;

  @media (max-width: $bp-min-md) {
    font-size: 24px;
  }

  @media (max-width: $bp-min-sm) {
    font-size: 20px;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
