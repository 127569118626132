<template>
  <ThemeWrapper>
    <div ref="TheRoom" class="TheRoom theme-background-primary">
      <ImageFullsize
        :src="
          currentRoom.room_codename
            ? `${imgdir}/img/m_${currentRoom.room_codename}.jpg`
            : `${imgdir}/${currentSpace.bg_image}`
        "
        align="cover"
      />
      <div class="TheRoom_Main">
        <RoomHeader />
        <div class="TheRoom_Inner" v-if="currentRoom.room_id">
          <RoomMain v-if="currentUser.user_id" ref="RoomMain" />
          <ImageFullsize
            v-if="currentSpace.fg_image"
            class="TheRoom_ImageFrame"
            :src="`${imgdir}/${currentSpace.fg_image}`"
            align="cover"
          />
          <RoomPanelRight
            @replayVideo="replayVideo"
            @toggleFullscreen="toggleFullscreen"
            :fullScreen="fullScreen"
          />
        </div>
      </div>

      <RoomMainToasts />

      <LoadingSpinner
        v-if="teleporting || loading || !currentUser.user_id"
        class="TheRoom_Loading"
      />
    </div>
  </ThemeWrapper>
</template>

<script>
import { getUserID } from "@/helper/helper.js";

import { mapState } from "vuex";
import ImageFullsize from "@/components/ImageFullsize.vue";
import RoomHeader from "@/components/RoomHeader.vue";
import RoomMain from "@/components/RoomMain/RoomMain.vue";
import RoomPanelRight from "@/components/RoomPanelRight/RoomPanelRight.vue";
import RoomMainToasts from "@/components/RoomMain/RoomMainToasts.vue";
import ThemeWrapper from "@/components/ThemeWrapper.vue";
import LoadingSpinner from "@/components/Basic/LoadingSpinner.vue";

export default {
  name: "TheRoom",
  components: {
    RoomHeader,
    ImageFullsize,
    RoomMain,
    RoomPanelRight,
    RoomMainToasts,
    ThemeWrapper,
    LoadingSpinner,
  },
  data() {
    return {
      interval: null,
      loading: false,
      fullScreen: false,
      toastCount: 0,
    };
  },
  computed: {
    ...mapState([
      "currentSpace",
      "currentUser",
      "currentRoom",
      "currentEvent",
      "teleporting",
      "imgdir",
    ]),
    refresh_interval() {
      // set the refresh_rate from the database
      if (this.currentEvent.refresh_rate) {
        return this.currentEvent.refresh_rate;
      } else {
        return 7000;
      }
    },
    loadingEvent() {
      return (
        this.currentUser?.user_id &&
        this.currentEvent.event_id !== this.currentUser.user_current_event
      );
    },
    loadingSpace() {
      return (
        this.currentUser?.user_id &&
        this.currentEvent?.event_id && // event must be loaded before loading space
        this.currentSpace.space_id !== this.currentUser.user_current_space
      );
    },
    loadingRoom() {
      return (
        this.currentUser?.user_id &&
        this.currentRoom.room_id !== this.currentUser.user_current_room
      );
    },
  },
  mounted() {
    // check if we can find a user ID
    // If so, load the user into the store
    const userID = getUserID(this.$store.state);
    console.log("userID", userID);
    if (!userID) {
      if (this.currentEvent.event_id) {
        alert("User not found, please log in again");
        this.$router.push({
          path: "/event/" + this.currentEvent.event_id_text,
          query: this.$route.query,
        });
        return;
      }
      alert("No event information, please visit the event landing page");
      return;
    }

    // then load the user information
    this.$store
      .dispatch("loadCurrentUserById", { user_id: userID })
      .then((user) => {
        console.log("user loaded", user);

        if (
          !user.user_current_event ||
          !user.user_current_space ||
          !user.user_current_room
        ) {
          if (!this.currentEvent.event_id) {
            alert("No event information, please visit the event landing page");
            return;
          }
          alert("Invalid user data incomplete, please log in again");
          this.$router.push({
            path: "/event/" + this.currentEvent.event_id_text,
            query: this.$route.query,
          });
        }
      });
    // once user has loaded, then watchers will take care of loading the event, space, and room

    // then set interval to poll new data
    console.log("referesh rate: ", this.refresh_interval);
    this.interval = setInterval(() => {
      this.fetchData(userID, "data update error");
    }, this.refresh_interval);

    // the keys we only load once when the user logs in
    // do we need to refresh this data as well?
    this.$store.dispatch("getKeyOptions").catch((err) => console.error(err));

    // detect fullscreen changes
    document.getElementsByTagName("body")[0].onfullscreenchange = (event) => {
      let elem = event.target;
      let isFullscreen = document.fullscreenElement === elem;
      this.fullScreen = isFullscreen;
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    loadingSpace: {
      handler() {
        if (this.loadingSpace) {
          // if there is no space, send to login
          if (!this.currentUser.user_current_space) {
            this.$router.push({
              path: "/event/" + this.currentEvent.event_id_text,
              query: this.$route.query,
            });
            return;
          }
          this.$store.dispatch("loadCurrentSpaceById", {
            space_id: this.currentUser.user_current_space,
          });
        }
      },
      immediate: true,
    },
    loadingRoom: {
      handler() {
        if (this.loadingRoom) {
          // if there is no room, send to login
          if (!this.currentUser.user_current_room) {
            this.$router.push({
              path: "/event/" + this.currentEvent.event_id_text,
              query: this.$route.query,
            });
            return;
          }
          this.$store.dispatch("loadCurrentRoomById", {
            room_id: this.currentUser.user_current_room,
          });
        }
      },
      immediate: true,
    },
    loadingEvent: {
      handler() {
        if (this.loadingEvent) {
          // if there is no event, send to login
          if (!this.currentUser.user_current_event) {
            this.$router.push({
              path: "/event/" + this.currentEvent.event_id_text,
              query: this.$route.query,
            });
            return;
          }
          this.$store.dispatch("loadCurrentEventById", {
            event_id: this.currentUser.user_current_event,
          });
        }
      },
      immediate: true,
    },
  },
  sockets: {
    // listening to socket server connect events
    connect() {
      // whenever socket connects, send current user
      // including when user is undefined
      // ensure right user is attached to socket even if server restarts
      this.$socket.client.emit("userLogin", this.currentUser?.user_id);
      console.log(
        "emit userLogin on socket connect",
        this.currentUser?.user_id
      );
      // load all data in case we missed some things when the socket was disconnected
      if (this.currentUser?.user_id) {
        this.fetchData(
          this.currentUser?.user_id,
          "socket connected fetch data error"
        );
      }
    },
  },
  methods: {
    handleDataError(err) {
      // if there was a 404 error in fetching data
      // could be because the user doesn't have a current room or space
      // then send them to the login

      // first check what kind of error it is
      if (!err || !err.response || !err.response.status) {
        // some odd thing happened - don't send to login
        return;
      }
      if (err.response.status != 404) {
        // some other error, maybe a connection error
        // don't send to login
        return;
      }

      if (!this.currentEvent.event_id_text) {
        // if there is no event information, there's nothing we can do to help them
        // don't send to login
        return;
      }
      const path = "/event/" + this.currentEvent.event_id_text;
      this.$router.push({ path, query: this.$route.query });
    },
    replayVideo() {
      this.$refs.RoomMain.replayVideo();
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        document.getElementsByTagName("body")[0].requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    // fetch data for that user, used on mount then set interval for polling
    fetchData: async function (id, errorMessage) {
      console.log("fetching data");
      this.$store
        .dispatch("loadAllData", id)
        .then(() => {})
        .catch((err) => {
          console.error(errorMessage, err);
          this.handleDataError(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.TheRoom {
  width: 100%;
  top: 0;
  left: 0;
  @media (min-width: $bp-min-lg) {
    position: absolute;
    height: 100%;
    overflow: hidden;
  }
  &_Loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  &_Main {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
  }
  &_ImageFrame {
    pointer-events: none;
    opacity: 0.8;
  }
  &_Inner {
    flex-grow: 1;
    position: relative;
    @media (min-width: $bp-min-lg) {
      height: 100%;
      min-height: 0;
    }
  }
}
</style>
