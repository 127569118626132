<template>
  <div class="LoadingSpinner">
    <div class="LoadingSpinner_Inner">
      <BSpinner class="LoadingSpinner_Spinner --dark" variant="dark" />
      <BSpinner class="LoadingSpinner_Spinner --light" variant="light" />
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  name: "TheRoom",
  components: {
    BSpinner,
  },
};
</script>

<style lang="scss" scoped>
.LoadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  &_Inner {
    width: 35px;
    height: 35px;
    position: relative;
  }
  &_Spinner {
    position: absolute;
    &.--light {
      top: 0;
      left: 0;
    }
    &.--dark {
      bottom: 0;
      right: 0;
    }
  }
}
</style>
