import axios from "axios";
import { nodeserver } from "../../agora-conf.json";

const nodeApiUrl = `${nodeserver.protocol}${nodeserver.host}${
  nodeserver.port ? `:${nodeserver.port}` : ""
}${nodeserver.apidir}`;

const errorLog = (err) => {
  if (err.response) {
    if (err.response.data) {
      console.error(err.response.data);
      return;
    }
    console.error(err.response);
    return;
  }
  console.error(err);
};

const spaceActions = {
  loadCurrentSpaceById({ commit, state, dispatch }, { space_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/spaces/${space_id}`)
        .then((res) => {
          const space = res.data;
          commit("setCurrentSpace", space);
          // whenever we load the space
          // we also need to load the rooms
          // and the users in this space
          return Promise.all([
            dispatch("loadRoomsListBySpace", {
              space_id: space.space_id,
            }),
            // BUG: Loading users for event prevents from seeing those on same space coming from other event "doors".
            // dispatch("loadUsersListForEvent", {
            //   event_id: state.currentEvent.event_id,
            // }),
            dispatch("loadUsersListForSpace", {
              space_id: state.currentSpace.space_id,
            }),
          ]);
        })
        .then(() => {
          resolve(state.currentSpace);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  loadSpacesForUserAndEvent({ commit, state }) {
    // list of spaces available in the event
    // plus spaces available for the user
    const userSpaces =
      state.currentUser?.userSpaces?.map((us) => us.space_id) || [];
    const eventSpaces =
      state.currentEvent?.eventSpaces?.map((es) => es.space_id) || [];
    const space_ids = [...userSpaces, ...eventSpaces];
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/spaces?ids=${space_ids}`)
        .then((res) => {
          const spaces = res.data;
          commit("setSpaces", spaces);
          resolve(spaces);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  updateSpace({}, { space_id, ...values }) {
    // Update the database for that space
    return new Promise((resolve, reject) => {
      axios
        .put(`${nodeApiUrl}/spaces/${space_id}`, { ...values })
        .then((res) => {
          // SOCKET_UPDATESPACE takes care of updating the store for all clients
          resolve(res);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  updateEventSpaces({ state }, { space_id, ...values }) {
    // used to hide and show spaces
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${nodeApiUrl}/event_spaces?event_id=${state.currentEvent.event_id}&space_id=${space_id}`,
          { ...values }
        )
        .then((res) => {
          // event will be updated via socket
          resolve(res);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
};

export default spaceActions;
