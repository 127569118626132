export const messages = {
  en: {
    message: {
      hello: "Hello, {name}!",
      success: "Updated",
      login_normal: "Login",
      login_admin: "Moderators",
      login_welcome_back: "Welcome back,",
      login_not_you: "Not you?",
      login_new_user: "New user",
      login_password_prompt: "Access code:",
      login_password_invalid: "Invalid access code",
      login_compatibility:
        "iPhones and iPads might have problems accessing your camera. We recommend to login from a computer using Google Chrome.",
      login_issue:
        "There's currently an issue with Agora. Please try refreshing the page in a few minutes.",
      login_full:
        "This event is overcapacity. Please try again later, in case a placebecomes available or capacity gets increased.",
      login_alt: "Alternatively, watch the event here:",
      concierge_full:
        "The concierge is currently full. Please wait for a space to open up. If there's a general issue, an update should be issued soon.",
      settings: "Settings",
      settings_username: "Your name",
      settings_user_tagline: "Your tagline",
      settings_room_tagline: "Room tagline",
      settings_lock_room: "🔐 Lock room",
      settings_unlock_room: "✨ Unlock room",
      settings_unlockable: "This room can't be locked",
      settings_text:
        'Click "Settings" to expand/collapse the settings to lock a room, change your name, etc...',
      invite_received: "{person} has invited you to join them in {room}",
      invite_sent:
        "Your invite for {person} to {room} has been sent successfully!",
      invite_not_sent:
        "Your invite for {person} to {room} couldn't be sent. Please try again",
      invite_wait: "Please wait before sending another invite to this person",
      invite_tooltip: "Invite {person} to your room",
      invite_button: "Join now!",
      panel_expand_tooltip: "Menu - Expand",
      panel_collapse_tooltip: "Menu - Collapse",
      panel_video_playing_tooltip: "Intro video playing",
      panel_video_again_tooltip: "Watch the intro video again",
      panel_enter_fullscreen_tooltip: "Switch to full screen mode",
      panel_exit_fullscreen_tooltip: "Exit full screen mode",
      space_change: " Exit {departing_space} to enter {destination_space}",
    },
  },
  fr: {
    message: {
      hello: "Bonjour, {name}!",
      login_normal: "Connexion",
      login_admin: "Modérateurs",
      login_welcome_back: " Bon retour parmi nous,",
      login_not_you: "Pas vous?",
      login_new_user: "Nouvel utilisateur",
      login_password_prompt: "Code d'accès :",
      login_password_invalid: "Code d'accès invalide",
      login_compatibility:
        "Les iPhone et iPad peuvent avoir des problèmes pour accéder à votre appareil photo. Nous vous recommandons de vous connecter à partir d'un ordinateur à l'aide de Google Chrome.",
      login_issue:
        "Il y a actuellement un problème avec Agora. Veuillez essayer d'actualiser la page dans quelques minutes.",
      login_full:
        "Cet événement est en surcapacité. Veuillez réessayer plus tard, au cas où une place se libère ou que la capacité augmente.",
      login_alt: "Sinon, regardez l'événement ici :",
      concierge_full:
        "La conciergerie est actuellement pleine. Veuillez attendre qu'un espace se libère. S'il y a un problème général, une mise à jour devrait être publiée bientôt.",
      settings: "Réglages",
      success: "Mis à jour",
      settings_username: "Votre nom",
      settings_user_tagline: "Votre slogan",
      settings_room_tagline: "Sous-titre de cette salle",
      settings_lock_room: "🔐 Verrouiller salle",
      settings_unlock_room: "✨ Déverrouiller salle",
      settings_unlockable: "Non verrouillable",
      settings_text:
        'Cliquez sur "Réglages" pour vérrouiller une salle ou changer vos paramètres utilisateur.',
      invite_received: "{person} vous invite à rejoindre la salle {room} ",
      invite_sent:
        "Votre invitation à {person} à {room} a été envoyée avec succès !",
      invite_not_sent:
        "Votre invitation pour {person} à {room} n'a pas pu être envoyée. Veuillez réessayer",
      invite_wait:
        "Veuillez patienter avant d'envoyer une autre invitation à cette personne",
      invite_tooltip: "Inviter {person} à rejoindre cette salle",
      invite_button: "S'y joindre!",
      panel_expand_tooltip: "Menu - Dévoiler",
      panel_collapse_tooltip: "Menu - Cacher",
      panel_video_playing_tooltip: "Vidéo d'intro en cours de lecture",
      panel_video_again_tooltip: "Revoir la vidéo d'intro",
      panel_enter_fullscreen_tooltip: "Passer en mode plein écran",
      panel_exit_fullscreen_tooltip: "Quitter le mode plein écran",
      space_change:
        "Quittez {departing_space} pour entrer dans {destination_space}",
    },
  },
  de: {
    message: {
      hello: "Guten Tag, {name}!",
      success: "Erfolgt!",
      settings_username: "Ihr Name",
      settings_user_tagline: "Ihr Slogan",
      settings_room_tagline: "Slogan des Raumes",
      settings_lock_room: "🔐 Sperren des Raums",
      settings_unlock_room: "✨ Entsperren des Raums",
      settings: "Einstellungen",
      settings_unlockable: "--",
      settings_text:
        "Klicken Sie auf Einstellungen, um einen Raum zu sperren oder um Ihre Nutzereinstellungen zu ändern.",
      toast_invite: "{person} lädt Sie ein, dem Raum beizutreten {room} ",
      toast_sent: "Ihre Einladung wurde erfolgreich an gesendet {person}",
    },
  },
};
