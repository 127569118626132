import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PageWrapper from "./components/PageWrapper.vue";

import VueSocketIOExt from "vue-socket.io-extended";
import socket from "./socket/socket";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { ToastPlugin } from "bootstrap-vue";

import VueI18n from "vue-i18n";
import { messages } from "./data/i18n";

Vue.use(VueSocketIOExt, socket, { store });
Vue.use(ToastPlugin);
Vue.use(VueI18n);
Vue.component("page-wrapper", PageWrapper);

const i18n = new VueI18n({
  locale: "de",
  messages,
});

Vue.config.productionTip = false;

// Vue.config.devtools = true;

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
