<template>
  <BModal
    :title="`User: ${userSettings ? userSettings.user_name : ''}`"
    ok-only
    ref="userModal"
    @hidden="userSettings = {}"
  >
    <div v-if="userSettings" class="container-fluid">
      <div class="row mb-4">
        <div class="col">
          <button
            :disabled="
              userSettings.user_current_room == $store.state.currentRoom.room_id
            "
            @click="teleportHere(userSettings)"
          >
            Teleport {{ userSettings.user_name }} to this room ({{
              $store.state.currentRoom.room_name
            }})
          </button>
        </div>
      </div>

      <template v-if="$store.state.currentEvent.concierge_room">
        <div class="row mb-4">
          <div class="col">
            <button
              :disabled="
                userSettings.user_current_room ==
                $store.state.currentEvent.concierge_room
              "
              @click="
                teleportSpecificRoom(
                  userSettings,
                  $store.state.currentEvent.concierge_room
                )
              "
            >
              Teleport {{ userSettings.user_name }} to EVENT CONCIERGE
            </button>
          </div>
        </div>
      </template>

      <template v-if="$store.state.currentEvent.stage_room">
        <div class="row mb-4">
          <div class="col">
            <button
              :disabled="
                userSettings.user_current_room ==
                $store.state.currentEvent.stage_room
              "
              @click="
                teleportSpecificRoom(
                  userSettings,
                  $store.state.currentEvent.stage_room
                )
              "
            >
              Teleport {{ userSettings.user_name }} to EVENT STAGE
            </button>
          </div>
        </div>
      </template>

      <!--      
      <div class="row mb-4">
        <div class="col">
          <button
            @click="teleportStage(userSettings)"
          >
            Teleport {{ userSettings.user_name }} to the Stage
          </button>
        </div>
      </div>
-->
      <template v-if="$store.state.currentEvent.event_id_text == 'ap'">
        <strong>Astral Portal Menu</strong>
        <div class="row mb-4">
          <div class="col">
            <button @click="teleportSpecificRoom(userSettings, 20072406)">
              Teleport {{ userSettings.user_name }} to the EXPEDITION SUPPORT
            </button>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <button @click="giveUserAccessTo(2122, 20072520)">
              <i class="fas fa-door-open"></i>
              Send {{ userSettings.user_name }} to Jungle Ruins
            </button>
          </div>
        </div>

        <ModalUserSettingsKey
          v-for="k in keys"
          :key="k.id"
          :user="userSettings"
          :userKeys="userKeys"
          :k="k"
          @hide="$refs.userModal.hide()"
        />

        <div class="row mb-4">
          <div class="col">
            <button @click="accessAstralPlane()">
              <i class="fas fa-door-open"></i>
              Send {{ userSettings.user_name }} to Astral Plane
            </button>
          </div>
        </div>
      </template>

      <template v-if="$store.state.currentEvent.event_id_text == 'composite26'">
        <strong>Composite 26 Menu</strong>
        <div class="row mb-4">
          <div class="col">
            <button @click="teleportSpecificRoom(userSettings, 210225122107)">
              Teleport {{ userSettings.user_name }} to CONCIERGERIE
            </button>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <button @click="teleportSpecificRoom(userSettings, 210225122108)">
              Teleport {{ userSettings.user_name }} to LA SCÈNE
            </button>
          </div>
        </div>
      </template>

      <strong>Other options</strong>
      <div class="row mb-4" v-if="$store.state.currentUser.admin >= 2">
        <div class="col">
          <button
            :disabled="userSettings.admin == 2"
            @click="makeAdmin(userSettings)"
          >
            Make {{ userSettings.user_name }} an admin.
          </button>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <button
            :disabled="userSettings.admin == 1"
            @click="makeModerator(userSettings)"
          >
            Make {{ userSettings.user_name }} a moderator.
          </button>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <button @click="sendUserToLogin(userSettings)">
            Send {{ userSettings.user_name }} to login.
          </button>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { mapState } from "vuex";
import ModalUserSettingsKey from "@/components/ModalUserSettingsKey.vue";

export default {
  components: {
    BModal,
    ModalUserSettingsKey,
  },
  data() {
    return { userKeys: [], userSettings: {} };
  },
  computed: {
    ...mapState(["keys"]),
  },
  methods: {
    teleportHere(user) {
      this.$store
        .dispatch("teleportUserHere", user)
        .then(() => this.$refs.userModal.hide());
    },
    teleportSpecificRoom(user, room) {
      this.$store
        .dispatch("teleportUserToRoom", {
          user_id: user.user_id,
          room_id: room,
        })
        .then(() => this.$refs.userModal.hide());
    },
    teleportStage(user) {
      this.$store
        .dispatch("teleportUserToRoom", {
          user_id: user.user_id,
          room_id: 201127122108, //LA SCÈNE adopte_inc
        })
        .then(() => this.$refs.userModal.hide());
    },
    makeModerator(user) {
      this.$store
        .dispatch("makeUserModerator", user.user_id)
        .then(() => this.$refs.userModal.hide());
    },
    makeAdmin(user) {
      this.$store
        .dispatch("makeUserAdmin", user.user_id)
        .then(() => this.$refs.userModal.hide());
    },
    show(user) {
      this.userSettings = user;
      this.$refs.userModal.show();
      this.$store
        .dispatch("getUsersKeys", user.user_id)
        .catch((err) => {
          this.userKeys = [];
          console.log(err);
        })
        .then((keys) => {
          this.userKeys = keys;
        });
    },
    giveUserAccessTo(space_id, room_id) {
      this.$store
        .dispatch("giveUserAccessToSpace", {
          user: this.userSettings,
          space_id,
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          //220323 console.log("teleporting");
          this.$store
            .dispatch("teleportUserToRoom", {
              user_id: this.userSettings.user_id,
              room_id: room_id,
              space_id: space_id,
            })
            .catch((err) => {
              console.log(err);
            })
            .then(() => {
              this.$refs.userModal.hide();
            });
        });
    },
    accessAstralPlane() {
      this.$store
        .dispatch("accessAstralPlane", this.userSettings)
        .catch()
        .then(() => {
          this.$refs.userModal.hide();
        });
    },
    sendUserToLogin(user) {
      this.$store
        .dispatch("updateUser", {
          user_id: user.user_id,
          user_current_room: null,
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.$refs.userModal.hide();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
