import moment from "moment-timezone";

const getUserID = (storeState) => {
  // console.log({ storeState });
  // first see if user is already set in storeState
  if (storeState.currentUser?.user_id) {
    // console.log("user already in storeState", storeState.currentUser.user_id);
    return storeState.currentUser.user_id;
  }

  // then try to get from local storage
  const user = JSON.parse(localStorage.getItem("agora"));
  if (user && Number.isInteger(user.user_id)) {
    // console.log("got from local");
    return user.user_id;
  }

  // otherwise we can't find a user
  return 0;
};

export { getUserID };
