<template>
  <div />
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["currentUser", "currentSpace", "currentEvent"]),
  },
  sockets: {
    // listening to socket server invite event
    invite(data) {
      const { inviter, room } = data;
      const title = `${this.$t("message.invite_received", {
        person: inviter.user_name,
        room: room.room_name,
      })}`;
      this.makeInviteToast(title, room.room_id);
    },
    inviteSuccess(data) {
      const { invitee, room } = data;
      const title = `${this.$t("message.invite_sent", {
        person: invitee.user_name,
        room: room.room_name,
      })}`;
      this.makeInviteSuccessToast(title);
    },
    inviteFail(data) {
      const { invitee, room } = data;
      const title = `${this.$t("message.invite_not_sent", {
        person: invitee.user_name,
        room: room.room_name,
      })}`;
      this.makeInviteSuccessToast(title);
    },
    // listening to socket server broadcast event
    announcementtoallclients({ space_id, tmp_message }) {
      // Only display toast for folks in the space
      if (space_id === this.currentSpace.space_id) {
        this.makeAnnouncementToast(tmp_message);
      }
    },
  },
  methods: {
    // TODO: Generalized the make toast mathods
    makeInviteToast(title, room_id) {
      // Create button for switch room
      const h = this.$createElement;
      const id = `my-toast-${this.count++}`;

      var button_text = `${this.$t("message.invite_button")}`;

      const $button = h(
        "button",
        {
          on: {
            click: () => {
              this.$store.dispatch("teleport", room_id);
              this.$bvToast.hide(id);
            },
          },
          class: ["btn", "btn-info"],
        },
        button_text
      );

      // Set toast options
      const options = {
        title,
        id: id,
        toaster: "b-toaster-top-left",
        autoHideDelay: 10000,
        variant: "info",
        appendToast: true,
        solid: true,
      };

      // Fire toast for invitee
      this.$bvToast.toast([$button], options);
    },
    makeInviteSuccessToast(title) {
      // Create button for switch room
      const id = `my-toast-${this.count++}`;

      // Set toast options
      const options = {
        title,
        id: id,
        toaster: "b-toaster-top-left",
        autoHideDelay: 10000,
        variant: "info",
        appendToast: true,
        solid: true,
      };

      var message_text = `${this.$t("message.invite_wait")}`;

      // Fire toast for inviter
      this.$bvToast.toast(message_text, options);
    },
    makeAnnouncementToast(announcement) {
      // Set toast options
      const title = this.currentSpace.space_name;

      const options = {
        title,
        toaster: "b-toaster-top-left",
        autoHideDelay: 10000,
        variant: "secondary",
        appendToast: true,
        solid: true,
      };
      this.$bvToast.toast(announcement, options);
    },
  },
};
</script>
