import { render, staticRenderFns } from "./GreetingAgir.vue?vue&type=template&id=da48b7f2&scoped=true&"
import script from "./GreetingAgir.vue?vue&type=script&lang=js&"
export * from "./GreetingAgir.vue?vue&type=script&lang=js&"
import style0 from "./GreetingAgir.vue?vue&type=style&index=0&id=da48b7f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da48b7f2",
  null
  
)

export default component.exports