<template>
  <article>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/97v_1m2n-z8"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <br />
    <br />
    <h3>
      Dear Member of EO Montreal, welcome to the 1st EO Montréal Learning Event
      of the Year - at the EO Private Virtual Mansion for Cocktails, Conference,
      & Workshop!
    </h3>
    <br />
    <strong>For Best Experience, may we suggest:</strong>
    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Get your computers ready to join (iPhone
        and iPad NOT supported)
      </li>
      <li>
        <i class="fa fa-chrome"></i> Please join from your Chrome browser.
        Here’s a
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en"
          >link to install</a
        >
        in case you don’t use Chrome.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Your browser may
        prompt you to give video and audio permissions. Simply click allow.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Turn your phones off and enjoy the
        evening!
      </li>
    </ul>
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
