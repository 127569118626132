<template>
  <div class="row mb-4">
    <div class="col">
      <button v-if="userKey && !userKey.claimed" @click="sendUserToClaimKey()">
        <i class="fas fa-key" style="color: red"></i>
        Send {{ user.user_name }} to claim {{ k.name }}
      </button>
      <button
        v-else
        :disabled="!!userKey && userKey.claimed"
        @click="giveKey()"
      >
        <i class="fas fa-key" style="color: orange"></i>

        Give {{ k.name }} to {{ user.user_name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    k: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    userKeys: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    userKey() {
      if (!this.userKeys) {
        return undefined;
      }
      return this.userKeys.find((ek) => {
        return ek.key_id == this.k.id;
      });
    },
  },
  methods: {
    sendUserToClaimKey() {
      this.$store
        .dispatch("teleportUserToRoom", {
          user_id: this.user.user_id,
          room_id: this.userKey.room_id,
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.$emit("hide");
        });
    },
    giveKey() {
      this.$store
        .dispatch("giveKeyToUser", {
          key_id: this.k.id,
          user_id: this.user.user_id,
          room_id: this.user.user_current_room,
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.$emit("hide");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
