<template>
  <div
    class="ThemeWrapper"
    :class="`--theme-${$store.state.currentEvent.theme}`"
  >
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ThemeWrapper {
  color: white;

  ::v-deep {
    .theme-background-primary {
      background-color: black;
    }
    .theme-background-translucent {
      background-color: #00000080;
    }
    .theme-header {
      background-color: black;
    }
    .theme-form-text-area {
      background: #0009;
      color: #aaa;
    }
    .theme-button-primary {
      color: white;
      background-color: black;
      border: solid 1px #333;
      &:hover:not(.--disabled) {
        background-color: gray;
        color: white;
      }
    }
    .theme-button-controls {
      background-color: #1d1d1d;
    }
    .theme-button-secondary {
      background-color: #1d1d1d;
      color: white;
      transition: background-color 0.3s;
      opacity: 0.9;
      border: solid 0px black;
      &:hover {
        background-color: #888;
        color: white;
        box-shadow: 2px 2px 10 black;
      }
      &.--unclickable {
        color: #888;
        opacity: 1;
        &:hover {
          background-color: #222f;
        }
      }
      &.--disabled {
        background-color: #311f;
        color: #888;
        opacity: 1;

        &:hover {
          background-color: #222f;
        }
      }
    }
    .theme-user-text {
      opacity: 0.8;
      color: rgb(168, 168, 168);
      &.--video {
        color: lightblue;
      }
      &.--jitsi {
        color: white;
      }
      &.--current {
        color: #a1caf7;
      }
    }
  }
  &.--theme-white {
    color: black;
    ::v-deep {
      .theme-background-primary {
        background-color: white;
      }
      .theme-background-translucent {
        background-color: #ffffff80;
      }
      .theme-header {
        background-color: white;
      }
      .theme-form-text-area {
        background: #fff9;
      }
      .theme-button-primary {
        color: black;
        background-color: white;
        border: solid 1px rgb(242, 242, 242);
        &:hover:not(.--disabled) {
          background-color: gray;
          color: black;
        }
      }
      .theme-button-controls {
        background-color: #ebe8e8;
        color: black;
      }
      .theme-button-secondary {
        background-color: #ebe9e9;
        color: black;
        transition: background-color 0.3s;
        opacity: 0.9;
        border: solid 0px white;
        &:hover {
          background-color: #888;
          color: black;
          box-shadow: 2px 2px 10 white;
        }
        &.--unclickable {
          color: #888;
          opacity: 1;
          &:hover {
            background-color: rgb(231, 231, 231);
          }
        }
        &.--disabled {
          background-color: rgb(245, 190, 190);
          color: #888;
          opacity: 1;

          &:hover {
            background-color: rgb(226, 225, 225);
          }
        }
      }
      .theme-user-text {
        opacity: 0.8;
        color: rgb(95, 94, 94);
        &.--video {
          color: darkblue;
        }
        &.--jitsi {
          color: black;
        }
        &.--current {
          color: #007afc;
        }
      }
    }
  }
}
</style>
