<template>
  <article>
    <h2>Bienvenue Adoptés, Adopteurs et Partenaires de Adopte inc!</h2>
    <br />
    <p>
      Nous sommes heureux de vous avoir donné ce rendez-vous virtuel afin de
      reprendre contact avec vous tous. Lors de cette rencontre, nous
      officialiserons, entre autres, l’annonce des matchs “Adopteurs/Adoptés” et
      présenterons notre Fonds d’aide à l’innovation pour la cohorte actuelle.
      Nous aurons aussi droit à des témoignages vibrants d’adoptés qui ont fait
      leur marque au sein de notre mouvement. Nous profiterons également de
      cette tribune pour annoncer un nouveau projet spécial. À la fin de
      l’événement, on vous invitera à une activité de réseautage.
    </p>
    <p>
      Un GRAND merci aux fidèles partenaires d’Adopte inc. qui nous soutiennent
      dans cette aventure et dans toutes nos activités.
    </p>
    <p>
      En terminant, nous tenons à souligner l’apport tout spécial de nos hôtes
      d’aujourd’hui, Darya Akulshyna et Alfonso Hernandez, deux supers
      entrepreneurs qui nous permettent d’utiliser leur plateforme virtuelle
      unique et distinctive.
    </p>
    <p>
      Anne Marcotte
      <br />
      Nicolas Duvernois
    </p>
    <br />
    <strong
      >Il s’agit de notre première expérience dans l’organisation d’un événement
      virtuel et nous avons à cœur, qu’elle soit pour toi, la plus agréable et
      optimale possible. C’est pourquoi, nous t’invitons à lire très
      attentivement ce qui suit :
    </strong>
    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Il est essentiel que tu utilises un
        ordinateur pour te brancher à ta session car les iPhones et les
        <strong>iPads ne sont pas compatibles</strong> avec la plateforme. Si ce
        n’est pas possible pour toi, tu peux utiliser une tablette Android.
      </li>
      <li>
        <i class="fa fa-chrome"></i> L’utilisation des navigateurs Google Chrome
        ou Firefox est requise. Tu peux cliquer
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          ici
        </a>
        pour l’installer, si tu ne l’as pas.
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>Il faut absolument que tu
        autorises la connexion vidéo et audio, à chaque fois que demandé.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Il est possible que
        l’audio devienne muet lorsque tu changes de salles ou de tables, il
        suffit simplement de le réactiver.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Nous t’invitons à éteindre ton
        téléphone pour profiter pleinement du moment que tu passeras avec nous.
      </li>
    </ul>
    <br />
    <p>Bon événement !</p>
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
