import axios from "axios";
import { nodeserver } from "../../agora-conf.json";

const nodeApiUrl = `${nodeserver.protocol}${nodeserver.host}${
  nodeserver.port ? `:${nodeserver.port}` : ""
}${nodeserver.apidir}`;

const errorLog = (err) => {
  if (err.response) {
    if (err.response.data) {
      console.error(err.response.data);
      return;
    }
    console.error(err.response);
    return;
  }
  console.error(err);
};

const userActions = {
  createUser({ commit }, userData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${nodeApiUrl}/users/new`, userData)
        .then((res) => {
          const user = res.data;
          // whenever a user is created, it is set as the current user
          commit("setCurrentUser", user);
          resolve(user);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  updateUser({}, user) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${nodeApiUrl}/users/${user.user_id}`, user)
        .then((res) => {
          const user = res.data;
          // user update pushed via socket
          resolve(user);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  loginUser({ state, dispatch }, userData) {
    //todo: access code should not be sent to client, ever
    if (userData.access_code) {
      if (
        userData.access_code != state.currentEvent.access_code &&
        userData.access_code != state.currentEvent.access_code2
      ) {
        return new Promise((resolve, reject) => {
          var invalid_code = "Invalid access code."; //default message
          invalid_code = "Code d'accès invalide."; //temp hack for French
          //invalid_code = `${this.$t("message.login_password_invalid")}`; //this is not working - TypeError: _this.$ is not a function
          reject(invalid_code);
        });
      }
      if (userData.access_code == state.currentEvent.access_code2) {
        userData.admin = 2;
      } else if (userData.access_code == state.currentEvent.access_code) {
        userData.admin = 1;
      }
    }
    if (!userData.user_id) {
      return dispatch("createUser", userData);
    }
    return dispatch("updateUser", userData);
  },
  updateUserBatch({}, userIds, properties) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${nodeApiUrl}/users/batch`, {
          userIds,
          properties,
        })
        .then()
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  loadUserById({}, { user_id }) {
    // sets the current user from an id
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/users/${user_id}`)
        .then((res) => {
          const user = res.data;
          resolve(user);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  loadCurrentUserById({ commit, dispatch }, { user_id }) {
    // sets the current user from an id
    return new Promise((resolve, reject) => {
      dispatch("loadUserById", { user_id })
        .then((user) => {
          commit("setCurrentUser", user);
          // whenever we load the user
          // we need to get the key
          // and the spaces for that user
          dispatch("getUsersKeys", user.user_id);
          dispatch("loadSpacesForUserAndEvent");
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadUsersListForEvent({ commit }, { event_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/users?event_id=${event_id}`)
        .then((res) => {
          commit("setUsers", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  loadUsersListForSpace({ commit }, { space_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/users?space_id=${space_id}`)
        .then((res) => {
          commit("setUsers", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },

  moveToSpace({ state, commit, dispatch }, space) {
    return new Promise((resolve, reject) => {
      commit("setBoolean", { prop: "teleporting", value: true });
      dispatch("updateUser", {
        user_id: state.currentUser.user_id,
        user_current_space: space.space_id,
        user_current_room: space.default_room,
      })
        .then(() => {
          commit("setBoolean", { prop: "teleporting", value: false });
          resolve();
        })
        .catch((err) => {
          errorLog(err);
          commit("setBoolean", { prop: "teleporting", value: false });
          reject(err);
        });
    });
  },
  teleportUserHere({ state, dispatch }, user) {
    return dispatch("updateUser", {
      user_id: user.user_id,
      user_current_room: state.currentRoom.room_id,
    });
  },

  teleportUserToRoom({ dispatch }, { user_id, room_id, space_id }) {
    const data = { user_current_room: room_id };
    if (space_id) {
      data.user_current_space = space_id;
    }
    console.log("sending data", data);
    return dispatch("updateUser", {
      user_id,
      ...data,
    });
  },

  setCurrentUserStatus({ dispatch, state }, status) {
    // To keep track of when a user is watching a video
    // 0 = user has not started watching a video or loaded jitsi
    // 1 = user has loaded jitsi
    // 2 = user has started watching a video
    // 3 = user is claiming key
    return dispatch("updateUser", {
      user_id: state.currentUser.user_id,
      status,
    });
  },
  updateUserText({ dispatch, state }, text) {
    return dispatch("updateUser", {
      user_id: state.currentUser.user_id,
      user_text: text,
    });
  },
  updateUsername({ dispatch, state }, text) {
    return dispatch("updateUser", {
      user_id: state.currentUser.user_id,
      user_name: text,
    });
  },
  makeUserModerator({ dispatch }, user_id) {
    return dispatch("updateUser", {
      user_id: user_id,
      admin: 1,
    });
  },
  makeUserAdmin({ dispatch }, user_id) {
    return dispatch("updateUser", {
      user_id,
      admin: 2,
    });
  },
};

export default userActions;
