import axios from "axios";
import { nodeserver } from "../../agora-conf.json";

const nodeApiUrl = `${nodeserver.protocol}${nodeserver.host}${
  nodeserver.port ? `:${nodeserver.port}` : ""
}${nodeserver.apidir}`;

const errorLog = (err) => {
  if (err.response) {
    if (err.response.data) {
      console.error(err.response.data);
      return;
    }
    console.error(err.response);
    return;
  }
  console.error(err);
};

const roomActions = {
  loadCurrentRoomById({ commit }, { room_id }) {
    // sets the current room from an id
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/rooms/${room_id}`)
        .then((res) => {
          const room = res.data;
          commit("setCurrentRoom", room);
          resolve(room);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  loadRoomsListBySpace({ commit }, { space_id }) {
    // Gets the rooms for a specific space
    // While this is happening - loadingRoomNavigation is set to true
    return new Promise((resolve, reject) => {
      commit("setBoolean", { prop: "loadingRoomNavigation", value: true });
      axios
        .get(`${nodeApiUrl}/rooms?space_id=${space_id}`)
        .then((res) => {
          const rooms = res.data;
          commit("setRooms", rooms);
          resolve(rooms);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        })
        .finally(() => {
          commit("setBoolean", {
            prop: "loadingRoomNavigation",
            value: false,
          });
        });
    });
  },
  updateRoom({}, room) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${nodeApiUrl}/rooms/${room.room_id}`, room)
        .then((res) => {
          // room is updated via socket
          resolve(res.data);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  updateRoomText({ state, dispatch }, text) {
    return dispatch("updateRoom", {
      room_id: state.currentRoom.room_id,
      room_text: text,
    });
  },
  lockRoom({ state, dispatch }, locked) {
    return dispatch("updateRoom", {
      room_id: state.currentRoom.room_id,
      is_locked: locked ? 1 : 0,
    });
  },
  hideRoom({ dispatch }, { hide_status, room_id }) {
    return dispatch("updateRoom", {
      room_id: room_id,
      hidden: hide_status ? 1 : 0,
    });
  },
};

export default roomActions;
