<template>
  <article>
    <h1>G.Fr. Handel The Messiah</h1>
    <h2>Ensemble Caprice - Ensemble vocal Arts-Québec</h2>
    <p>
      with/avec vec Karina Gauvin (soprano) Maison symphonique<br />
      Matthias Maute – chef / conductor
    </p>
    <br />
    <p>
      [English will follow]<br />
      Chers amis de l’Ensemble Caprice,<br />

      Bienvenue à notre expérience musicale spéciale!
    </p>
    <p>
      Des choeurs et airs célèbres du chef d’oeuvre de G. Fr. Handel, Le Messie,
      combinés avec deux œuvres des compositeurs Mécénat Musica en résidence
      Jaap Nico Hamburger et Matthias Maute.
    </p>
    <p>Nous recommandons:</p>

    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Il est essentiel que vous utilisiez un
        ordinateur pour vous brancher à ta session car les iPhones et les
        <strong>iPads ne sont pas compatibles</strong> avec la plateforme.
      </li>
      <li>
        <i class="fa fa-chrome"></i> L’utilisation des navigateurs Google Chrome
        ou Firefox est requise. Vous pouvez cliquer
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          ici
        </a>
        pour l’installer, si vous ne l'avez pas.
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>Il faut absolument que
        vous autorisiez la connexion vidéo et audio, à chaque fois que demandé.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Il est possible que
        l’audio devienne muet lorsque vous changez de salles ou de tables, il
        suffit simplement de le réactiver.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Svp éteignez vos téléphones
        cellulaires et profitez pleinement du concert!
      </li>
    </ul>

    <p>
      <br /><br /><br />
      Dear friends of Ensemble Caprice,<br />
      Welcome to this special musical experience!
    </p>
    <p>
      Famous choruses and arias from Handel’s masterpiece “The Messiah” combined
      with two pieces by Mécénat Musica composers in residence Jaap Nico
      Hamburger and Matthias Maute.
    </p>

    <p>For best experience we suggest:<br /></p>

    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Get your computers ready to join
        <strong>(iPhone and iPad NOT supported) </strong>
      </li>
      <li>
        <i class="fa fa-chrome"></i> Please join from your Chrome browser.
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Here
        </a>
        's a link to install it if you don't have it.
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>
        Your browser may prompt you to give video and audio permissions. Simply
        click allow.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>
        Your browser may prompt you to give video and audio permissions. Simply
        click allow.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Turn your phones off and enjoy the
        evening!
      </li>
    </ul>

    <br /><br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
