<template>
  <article>
    <h2>Fête nationale du Québec</h2>
    <h3>Réception réseautage virtuelle</h3>
    <br />
    <p>
      En cette année du 50e anniversaire de la présence du Québec en Allemagne,
      en Suisse et en Autriche, la Délégation générale du Québec à Munich et son
      Antenne à Berlin souhaitent célébrer avec vous la fête nationale du
      Québec.
    </p>
    <p>
      Ne pouvant pas encore nous réunir physiquement pour une réception "comme
      avant", nous vous proposons une réception virtuelle nouveau genre où le
      réseautage version 2.0 sera à l'honneur!
    </p>
    <p>Quelques consignes pour améliorer votre expérience:</p>
    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Il est fortement conseillé d’utiliser un
        ordinateur pour profiter au maximum de l’expérience. Les
        <strong>iPhones et iPads sont déconseillés, </strong> car certaines
        versions peuvent poser des problèmes avec le partage du microphone et de
        la caméra.
      </li>
      <li>
        <i class="fa fa-chrome"></i> L’utilisation du navigateur Google Chrome
        est recommandée. Vous pouvez cliquer
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          ici
        </a>
        pour l’installer.
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>Si demandé, autorisez la
        connexion VIDÉO et AUDIO.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Votre microphone
        pourrait automatiquement devenir muet lorsque vous changez de
        destination, il suffit simplement de le réactiver.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Nous vous invitons à éteindre votre
        téléphone afin de profiter pleinement du moment.
      </li>
    </ul>
    <br />
    <p>Bon événement !</p>
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
