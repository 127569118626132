import { render, staticRenderFns } from "./GreetingNouvLR.vue?vue&type=template&id=7487b0d0&scoped=true&"
import script from "./GreetingNouvLR.vue?vue&type=script&lang=js&"
export * from "./GreetingNouvLR.vue?vue&type=script&lang=js&"
import style0 from "./GreetingNouvLR.vue?vue&type=style&index=0&id=7487b0d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7487b0d0",
  null
  
)

export default component.exports