<template>
  <component
    :is="noButton ? 'div' : 'button'"
    class="RoomNavigationButton btn theme-button-primary"
    :class="{ '--small': small, '--disabled': disabled, 'btn-link': !noButton }"
    :disabled="disabled"
    @click="click"
  >
    <slot />
  </component>
</template>

<script>
export default {
  components: {},
  props: {
    small: Boolean,
    disabled: Boolean,
    noButton: Boolean,
  },
  methods: {
    click(e) {
      e.preventDefault();
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.RoomNavigationButton {
  min-height: 64px;
  border-radius: 10px !important;
  font-size: 21px;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: 4px 0;
  position: relative;
  z-index: 1;
  transition: background-color 0.3s;
  position: relative;
  width: 100%;
  transition: background-color 0.3s color 0.3s;
  &.--small {
    padding: 10px 24px;
    min-height: 32px;
    h4 {
      font-size: 18px;
      margin: 0;
      padding: 0;
    }
  }
  &:hover:not(.--disabled) {
    text-decoration: none;
  }
}
div.btn {
  cursor: default !important;
}
</style>
