var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"RoomPanelRight",class:{ '--collapsed': _vm.navigationCollapsed }},[_c('div',{staticClass:"RoomPanelRight_controls"},[(_vm.currentRoom.video_link)?_c('button',{staticClass:"\n        RoomPanelRight_VideoButton\n        btn btn-dark\n        mt-1\n        theme-button-controls\n      ",attrs:{"title":_vm.currentUser.status == 2
          ? _vm.$t('message.panel_video_playing_tooltip')
          : _vm.$t('message.panel_video_again_tooltip'),"disabled":_vm.currentUser.status == 2},on:{"click":_vm.replayVideo}},[_c('i',{staticClass:"fas fa-video"})]):_vm._e(),(_vm.currentSpace.agenda_content)?_c('button',{staticClass:"\n        RoomPanelRight_VideoButton\n        btn btn-dark\n        mt-1\n        theme-button-controls\n      ",attrs:{"title":"Agenda"},on:{"click":function($event){return _vm.spaceAgenda(_vm.currentRoom)}}},[_c('i',{staticClass:"fas fa-calendar-day"})]):_vm._e(),(
        _vm.currentRoom.room_id != _vm.currentEvent.concierge_room ||
        (_vm.currentSpace.is_menu_collapsed_globally &&
          _vm.navigationCollapsed &&
          _vm.currentRoom.room_id != _vm.currentEvent.concierge_room)
      )?_c('button',{staticClass:"\n        RoomPanelRight_VideoButton\n        btn btn-dark\n        mt-1\n        theme-button-controls\n      ",class:{ '--full': _vm.conciergeFull },attrs:{"id":"collapsed_concierge","title":'Concierge'},on:{"click":function($event){return _vm.teleportConcierge()}}},[_c('i',{staticClass:"fas fa-concierge-bell"})]):_vm._e(),_c('BPopover',{ref:"popoverNavCollapseConcierge",attrs:{"placement":"left","variant":"dark","target":"collapsed_concierge","triggers":"manual"}},[_vm._v(" Concierge ")]),_c('button',{staticClass:"\n        RoomPanelRight_VideoButton\n        btn btn-dark\n        mt-1\n        theme-button-controls\n      ",attrs:{"id":"collapse_toggle","title":_vm.navigationCollapsed
          ? _vm.$t('message.panel_expand_tooltip')
          : _vm.$t('message.panel_collapse_tooltip')},on:{"click":_vm.toggleNavigation}},[_vm._v(" "+_vm._s(_vm.navigationCollapsed ? "<" : ">")+" ")]),_c('BPopover',{ref:"popoverNavCollapseMode",attrs:{"placement":"left","variant":"dark","target":"collapse_toggle","triggers":"manual"}},[(_vm.navigationCollapsed)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("message.panel_expand_tooltip"))+" ")]):_vm._e(),(!_vm.navigationCollapsed)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("message.panel_collapse_tooltip"))+" ")]):_vm._e()]),_c('button',{staticClass:"btn btn-dark TheRoom_Fullscreen mt-1 theme-button-controls",attrs:{"title":_vm.fullScreen
          ? _vm.$t('message.panel_exit_fullscreen_tooltip')
          : _vm.$t('message.panel_enter_fullscreen_tooltip')},on:{"click":function($event){return _vm.$emit('toggleFullscreen')}}},[(_vm.fullScreen)?_c('i',{staticClass:"fas fa-compress-arrows-alt"}):_c('i',{staticClass:"fas fa-expand-arrows-alt"})]),(_vm.currentUser.admin >= 2)?_c('button',{staticClass:"btn btn-link mt-1",attrs:{"title":"Admin"},on:{"click":function($event){return _vm.roomAdminSettings(_vm.currentRoom)}}},[_c('i',{staticClass:"fas fa-cog p-0"})]):_vm._e()],1),_c('ModalUserSettings',{ref:"userModal"}),_c('ModalRoomAdmin',{ref:"ModalRoomAdmin"}),_c('ModalSpaceAgenda',{ref:"ModalSpaceAgenda"}),_c('RoomNavigationHeader',{on:{"userSettings":_vm.openUserSettings}}),_c('div',{staticClass:"RoomPanelRight_Outer"},[_c('div',{staticClass:"RoomPanelRight_container"},[_c('div',{staticClass:"RoomPanelRight_Inner"},[_c('div',{staticClass:"RoomPanelRight_Box theme-background-translucent"},[(!_vm.currentRoom.trap || _vm.currentUser.admin)?_c('RoomNavigation',{on:{"roomAdminSettings":_vm.roomAdminSettings,"openUserSettings":_vm.openUserSettings}}):_vm._e(),_c('RoomPanelRightSettings')],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }