d
<template>
  <div class="RoomPanelRight" :class="{ '--collapsed': navigationCollapsed }">
    <div class="RoomPanelRight_controls">
      <button
        class="
          RoomPanelRight_VideoButton
          btn btn-dark
          mt-1
          theme-button-controls
        "
        v-if="currentRoom.video_link"
        :title="
          currentUser.status == 2
            ? $t('message.panel_video_playing_tooltip')
            : $t('message.panel_video_again_tooltip')
        "
        @click="replayVideo"
        :disabled="currentUser.status == 2"
      >
        <i class="fas fa-video" />
      </button>

      <button
        v-if="currentSpace.agenda_content"
        class="
          RoomPanelRight_VideoButton
          btn btn-dark
          mt-1
          theme-button-controls
        "
        title="Agenda"
        @click="spaceAgenda(currentRoom)"
      >
        <i class="fas fa-calendar-day" />
      </button>

      <!-- CONCIERGE: TEMPORARILY BYPASSING THE v-if LOGIC TO MAKE IT ALWAYS VISIBLE -->
      <button
        id="collapsed_concierge"
        class="
          RoomPanelRight_VideoButton
          btn btn-dark
          mt-1
          theme-button-controls
        "
        :class="{ '--full': conciergeFull }"
        v-if="
          currentRoom.room_id != currentEvent.concierge_room ||
          (currentSpace.is_menu_collapsed_globally &&
            navigationCollapsed &&
            currentRoom.room_id != currentEvent.concierge_room)
        "
        :title="'Concierge'"
        @click="teleportConcierge()"
      >
        <i class="fas fa-concierge-bell" />
      </button>

      <BPopover
        ref="popoverNavCollapseConcierge"
        placement="left"
        variant="dark"
        target="collapsed_concierge"
        triggers="manual"
      >
        Concierge
      </BPopover>

      <button
        id="collapse_toggle"
        class="
          RoomPanelRight_VideoButton
          btn btn-dark
          mt-1
          theme-button-controls
        "
        :title="
          navigationCollapsed
            ? $t('message.panel_expand_tooltip')
            : $t('message.panel_collapse_tooltip')
        "
        @click="toggleNavigation"
      >
        {{ navigationCollapsed ? "&lt;" : "&gt;" }}
      </button>

      <BPopover
        ref="popoverNavCollapseMode"
        placement="left"
        variant="dark"
        target="collapse_toggle"
        triggers="manual"
      >
        <span v-if="navigationCollapsed">
          {{ $t("message.panel_expand_tooltip") }}
        </span>
        <span v-if="!navigationCollapsed">
          {{ $t("message.panel_collapse_tooltip") }}
        </span>
      </BPopover>

      <button
        @click="$emit('toggleFullscreen')"
        :title="
          fullScreen
            ? $t('message.panel_exit_fullscreen_tooltip')
            : $t('message.panel_enter_fullscreen_tooltip')
        "
        class="btn btn-dark TheRoom_Fullscreen mt-1 theme-button-controls"
      >
        <i v-if="fullScreen" class="fas fa-compress-arrows-alt"></i>
        <i v-else class="fas fa-expand-arrows-alt"></i>
      </button>
      <button
        v-if="currentUser.admin >= 2"
        class="btn btn-link mt-1"
        title="Admin"
        @click="roomAdminSettings(currentRoom)"
      >
        <i class="fas fa-cog p-0"></i>
      </button>
    </div>

    <ModalUserSettings ref="userModal" />
    <ModalRoomAdmin ref="ModalRoomAdmin" />
    <ModalSpaceAgenda ref="ModalSpaceAgenda" />

    <RoomNavigationHeader @userSettings="openUserSettings" />
    <div class="RoomPanelRight_Outer">
      <div class="RoomPanelRight_container">
        <div class="RoomPanelRight_Inner">
          <div class="RoomPanelRight_Box theme-background-translucent">
            <RoomNavigation
              v-if="!currentRoom.trap || currentUser.admin"
              @roomAdminSettings="roomAdminSettings"
              @openUserSettings="openUserSettings"
            />
            <RoomPanelRightSettings />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomNavigation from "@/components/RoomNavigation/RoomNavigation.vue";
import ModalUserSettings from "@/components/ModalUserSettings.vue";
import ModalRoomAdmin from "@/components/ModalRoomAdmin.vue";
import ModalSpaceAgenda from "@/components/ModalSpaceAgenda.vue";
import RoomPanelRightSettings from "@/components/RoomPanelRight/RoomPanelRightSettings.vue";
import RoomNavigationHeader from "@/components/RoomNavigation/RoomNavigationHeader.vue";

import { BPopover } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    RoomNavigation,
    RoomPanelRightSettings,
    ModalUserSettings,
    ModalRoomAdmin,
    ModalSpaceAgenda,
    RoomNavigationHeader,
    BPopover,
  },
  props: {
    fullScreen: Boolean,
  },
  computed: {
    ...mapState([
      "currentRoom",
      "currentUser",
      "currentSpace",
      "spaces",
      "navigationCollapsed",
      "currentEvent",
      "keys",
      "earnedKeys",
      "usersList",
      "eventAttendance",
    ]),

    conciergeFull() {
      const peopleInConcierge = this.usersList.filter((u) => {
        return (
          this.eventAttendance.includes(u.user_id) &&
          u.user_current_room == this.currentEvent.concierge_room
        );
      }).length;

      return peopleInConcierge >= this.currentEvent.concierge_room_capacity;
    },
  },
  watch: {
    navigationCollapsed() {
      this.firePopover(this.$refs.popoverNavCollapseMode);
      if (this.navigationCollapsed) {
        this.firePopover(this.$refs.popoverNavCollapseConcierge);
      }
    },
  },
  methods: {
    firePopover(refElement) {
      // Open popover then auto close it
      setTimeout(() => {
        refElement.$emit("open");
      }, 500);
      setTimeout(() => {
        refElement.$emit("close");
      }, 4000);
    },
    teleportConcierge() {
      const conciergeID = this.currentEvent.concierge_room;
      if (this.conciergeFull) {
        alert(this.$t("message.concierge_full"));
        return;
      }
      this.$store.dispatch("teleportUserToRoom", {
        user_id: this.currentUser.user_id,
        room_id: conciergeID,
      });
    },
    keysForUser(user_id) {
      // only show keys for current user
      if (user_id != this.currentUser.user_id) {
        return 0;
      }
      if (!this.earnedKeys) {
        return 0;
      }
      const keys = this.earnedKeys.filter((k) => {
        return k.claimed;
      });
      // only show if you don't have full set
      if (keys.length < this.keys.length) {
        return keys.length;
      }
      return 0;
    },
    openUserSettings(user) {
      this.$refs.userModal.show(user);
    },
    roomAdminSettings(room) {
      this.$refs.ModalRoomAdmin.show(room);
    },
    spaceAgenda(room) {
      this.$refs.ModalSpaceAgenda.show(room);
    },
    toggleNavigation() {
      this.$store.commit("setBoolean", {
        prop: "navigationCollapsed",
        value: !this.navigationCollapsed,
      });
    },
    replayVideo() {
      this.$emit("replayVideo");
    },
  },
};
</script>

<style lang="scss" scoped>
.SolidBg {
  background-color: black;
}
.prompt {
  margin: 16px 16px 0;
}

.RoomPanelRight {
  font-family: "Rubik";
  top: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  @media (min-width: $bp-min-lg) {
    margin-top: 0px; //todo: change to 70px and prevent header to take second line.
    width: 25%;
    position: absolute;
    background-color: transparent;
    transition: transform 0.3s;
    transform-origin: top right;
    &.--collapsed {
      transform: translate(100%);
    }
  }
  &_Outer {
    position: relative;
    flex-grow: 1;
  }
  &_container {
    @media (min-width: $bp-min-lg) {
      overflow: scroll;
      overflow-x: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &_Inner {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &_Box {
    border: 1px solid #fff0;
    border-radius: 10px;
    position: relative;
    margin: 6px 8px;
  }
  &_SectionContent {
    max-height: 100vh;
    transition: all 0.3s;
    &_Text {
      padding: 16px 8px;
      color: white;
    }
  }

  &_controls {
    position: absolute;
    top: 16px;
    left: 8px;
    z-index: 2;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;

    @media (max-width: $bp-min-lg) {
      left: 28px;
    }

    i {
      padding: 0;
    }
    button {
      padding: 6px 6px;
      border: none;
      opacity: 0.9;
    }
  }
  &_VideoButton {
    font-size: 1rem;
    position: relative;
    &.--full {
      color: darkred;
    }
    &:disabled {
      color: green;
    }
  }
}

.current-room {
  position: relative;
  margin: 0;
  padding-top: 21px;
  opacity: 1 !important; //hack to make the header look different than the other buttons
  border: none !important;
}

// Icons
i {
  padding-right: 16px;
  opacity: 0.8;
}

.room_text {
  font-size: 1rem;
  text-align: left;
  margin-top: 16px;
  max-height: 100px;
}
</style>
