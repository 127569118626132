var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.admin != 0 || !_vm.eventSpace.is_hidden)?_c('div',{staticClass:"SpaceNavigationItem",style:([_vm.eventSpace.is_hidden ? { opacity: 0.5 } : {}])},[_c('button',{staticClass:"SpaceNavigationButton btn theme-button-primary",attrs:{"title":_vm.$t('message.space_change', {
        departing_space: _vm.currentSpace.space_name,
        destination_space: _vm.space.space_name,
      })},on:{"click":function($event){return _vm.enterSpace(_vm.space)}}},[_c('h4',[_c('i',{staticClass:"fas fa-archway"}),_c('i',{staticClass:"fas fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.space.space_name)+" ")]),(_vm.space.space_description)?_c('div',{staticStyle:{"padding-top":"20px","font-size":"small"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.space.space_description)}})]):_vm._e()]),(_vm.currentUser.admin >= 2)?_c('button',{staticClass:"SpaceIconButton btn theme-button-primary",attrs:{"title":"Hide / Unhide this space from users"},on:{"click":function($event){return _vm.toggleHideSpace()}}},[_c('i',{staticClass:"fas",class:{
        'fa-eye-slash': _vm.eventSpace.is_hidden && !_vm.hiding,
        'fa-eye': !_vm.eventSpace.is_hidden && !_vm.hiding,
        'fa-clock': _vm.hiding,
      }})]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }