<template>
  <div class="ImageFullsize" :class="`--align-${align}`">
    <img
      ref="Image"
      class="ImageFullsize_img"
      :class="{ '--loaded': !loadingBackground }"
      :src="image"
    />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "cover",
    },
  },
  data() {
    return {
      image: this.src,
    };
  },
  computed: {
    loadingBackground() {
      return this.$store.state.loadingBackground;
    },
  },
  watch: {
    src(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$store.commit("setBoolean", {
          prop: "loadingBackground",
          value: true,
        });
        this.image = newValue;
      }
    },
  },
  mounted() {
    this.$store.commit("setBoolean", {
      prop: "loadingBackground",
      value: true,
    });
    if (this.$refs.Image.complete) {
      this.setLoaded();
    }
    this.$refs.Image.onload = () => {
      this.setLoaded();
    };
    this.$refs.Image.onerror = () => {
      if (this.image !== "/img/image.png") {
        this.image = "/img/image.png";
      } else {
        console.warn("error loading default background image", this.src);
        this.$store.commit("setBoolean", {
          prop: "loadingBackground",
          value: false,
        });
      }
    };
  },
  methods: {
    setLoaded() {
      this.$store.commit("setBoolean", {
        prop: "loadingBackground",
        value: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ImageFullsize {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  &_img {
    width: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 2s;
    &.--loaded {
      opacity: 1;
    }
  }
  &.--align-bottom {
    align-items: flex-end;
    img {
      object-fit: fill;
    }
  }
  &.--align-top {
    align-items: flex-start;
    img {
      object-fit: fill;
    }
  }
  &.--align-contain {
    img {
      object-fit: fill;
    }
  }
}
</style>
