<template>
  <div
    class="embed-responsive embed-responsive-16by9"
    style="overflow: hidden; padding-top: 0%; position: relative"
  >
    <iframe
      class="embed-responsive-item"
      src="https://www.youtube-nocookie.com/embed/CTOWcaQdpJ0?autoplay=1"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped></style>
