<template>
  <div v-if="!debug" ref="jitsiContainer" id="jitsiContainer" />
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      jitsiApi: null,
      jitsiRoomID: 0,
      jitsiUserID: 0,
    };
  },
  computed: {
    ...mapState([
      "currentUser",
      "currentRoom",
      "currentSpace",
      "loadingJitsi",
      "debug",
    ]),
    jitsi_domain() {
      var jitsi_domain = "nube.atypiclabs.com"; //default server, if none specified anywhere
      if (this.currentRoom.alt_jserver) {
        jitsi_domain = this.currentRoom.alt_jserver;
      } else if (this.currentSpace.alt_jserver) {
        jitsi_domain = this.currentSpace.alt_jserver;
      }
      return jitsi_domain;
    },
    current_experience_mode() {
      if (this.currentRoom.experience_mode) {
        return this.currentRoom.experience_mode;
      } else {
        return this.currentSpace.d_experience_mode;
      }
    },
  },
  mounted() {
    this.loadJitsi();
  },
  watch: {
    jitsi_domain(newValue) {
      this.loadJitsi(); //if the jitsi domain changed, reload Jitsi
    },
    currentUser(newuser, olduser) {
      if (olduser.user_name !== newuser.user_name) {
        this.updateJitsiUserName(newuser.user_name);
      }
    },
    currentRoom() {
      this.reloadJitsiIfRoomChanged();
    },
    debug() {
      if (this.debug == 0) {
        this.loadJitsi();
      }
    },
  },
  beforeDestroy() {
    if (this.loadingJitsi) {
      this.$store.commit("setBoolean", {
        prop: "loadingJitsi",
        value: false,
      });
    }
    if (this.jitsiApi) {
      this.jitsiApi.dispose();
    }
  },
  methods: {
    updateJitsiUserName(name) {
      if (this.jitsiApi) {
        this.jitsiApi.executeCommand("displayName", name);
      }
    },
    reloadJitsiIfRoomChanged() {
      if (!this.jitsiApi || this.currentRoom.room_id !== this.jitsiRoomID) {
        //console.log(          "Jitsi room changed from",
        //  this.jitsiRoomID,
        //  "to",
        //  this.currentRoom.room_id
        //);
        this.loadJitsi();
      }
    },
    loadJitsi() {
      // if jitsi is in process of loadiing, don't do anything
      if (this.loadingJitsi) {
        return;
      }
      // if jitsi already exists, first remove it
      if (this.jitsiApi) {
        this.jitsiApi.dispose();
        this.jitsiApi = null;
      }
      if (!this.currentUser.user_name || !this.currentRoom.room_id) {
        //console.error("invalid data for jitsi");
        return;
      }
      if (this.debug === 1 || this.debug === 2) {
        //console.log("skipping jitsi for debugging");
        return;
      }

      this.$store.commit("setBoolean", {
        prop: "loadingJitsi",
        value: true,
      });
      this.jitsiUserID = this.currentUser.user_id;
      this.jitsiRoomID = this.currentRoom.room_id;

      const fetch = require("node-fetch");

      const API_KEY =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmFwcGVhci5pbiIsImF1ZCI6Imh0dHBzOi8vYXBpLmFwcGVhci5pbi92MSIsImV4cCI6OTAwNzE5OTI1NDc0MDk5MSwiaWF0IjoxNjQ3MzE1Njc4LCJvcmdhbml6YXRpb25JZCI6MTUzMzM4LCJqdGkiOiIyMzZjMzJhOC0xNjg5LTQ5Y2EtYWEzNC1kMzgxYWRlYjMxYzMifQ.He_ceAJ8I3ugCkTdGpLTWGakm45_UA3-TatbWXoSOuE";

      const data = {
        endDate: "2022-03-16T03:40:00.000Z",
        fields: ["hostRoomUrl"],
      };

      function getResponse() {
        return fetch("https://api.whereby.dev/v1/meetings", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${API_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
      }

      getResponse().then(async (res) => {
        console.log("Status code:", res.status);
        const data = await res.json();
        console.log("Room URL:", data.roomUrl);
        console.log("Host room URL:", data.hostRoomUrl);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
