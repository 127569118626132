import Vue from "vue";
import Vuex from "vuex";
import createWebSocketPlugin from "../socket/websocketStorePlugin";
import socket from "../socket/socket";

import { imgdir } from "../../agora-conf.json";

import actions from "./actions.js";
import eventActions from "./eventActions.js";
import spaceActions from "./spaceActions.js";
import roomActions from "./roomActions.js";
import userActions from "./userActions.js";
import keyActions from "./keyActions.js";

Vue.use(Vuex);
const websocketPlugin = createWebSocketPlugin(socket);

export default new Vuex.Store({
  plugins: [websocketPlugin],
  state: {
    currentUser: {},
    currentRoom: {},
    currentSpace: {},
    currentEvent: {},
    eventAttendance: [], // list of IDs of connected users
    earnedKeys: [], // list of keys for current user
    roomsList: [], // list of rooms in current space
    usersList: [], // list of users in current space
    spaces: [], // list of spaces for event + current user
    teleporting: false,
    loadingUser: false,
    loadingEvent: false,
    loadingJitsi: false,
    loadingBackground: false,
    loadingRoomNavigation: false,
    loadingYoutube: false,
    watchingYoutube: false,
    imgdir,
    navigationCollapsed: false,
    keys: [],
    // debugging tools
    debug: 0,
    //theme: "white", // "default", we are getting this directly from the event database.
  },
  getters: {},
  mutations: {
    setCurrentRoom(state, room) {
      state.currentRoom = room;
    },
    updateCurrentRoom(state, room) {
      state.currentRoom = { ...state.currentRoom, ...room };
    },
    setBoolean(state, data) {
      state[data.prop] = data.value;
    },
    setCurrentUser(state, user) {
      // if the user ID changes, need to notify the socket
      if (user.user_id != state.currentUser.user_id) {
        this.$socket.emit("userLogin", user.user_id);
        console.log(
          "emit userLogin on setCurrentUser",
          user.user_id,
          state.currentUser.user_id
        );
      }
      state.currentUser = user;
    },
    setEarnedKeys(state, eks) {
      state.earnedKeys = eks;
    },
    setCurrentSpace(state, space) {
      state.currentSpace = space;
    },
    setCurrentEvent(state, event) {
      state.currentEvent = event;
      //console.log("event loaded", event); //debug:this is working
    },
    updateCurrentEvent(state, event) {
      state.currentEvent = { ...state.currentEvent, ...event };
    },
    updateCurrentSpace(state, space) {
      state.currentSpace = { ...state.currentSpace, ...space };
    },
    updateCurrentUser(state, user) {
      state.currentUser = { ...state.currentUser, ...user };
    },
    setCurrentUserProperty(state, data) {
      state.currentUser[data.prop] = data.value;
    },
    setRooms(state, rooms) {
      state.roomsList = rooms;
    },
    setUsers(state, users) {
      state.usersList = users;
    },
    setSpaces(state, spaces) {
      state.spaces = spaces;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setDebug(state, debug) {
      state.debug = debug;
    },
    setKeys(state, keys) {
      state.keys = keys;
    },
    SOCKET_NAVEXPAND(state, space_id) {
      if (state.currentSpace.space_id == space_id) {
        state.navigationCollapsed = false;
      }
    },
    SOCKET_NAVCOLLAPSE(state, space_id) {
      if (state.currentSpace.space_id == space_id) {
        state.navigationCollapsed = true;
      }
    },
    SOCKET_UPDATESPACE(state, space) {
      console.log("SOCKET_UPDATESPACE", space);

      // whenever space is updated by the api
      // it emits an event via sockets with the updates space
      // if it's the current space, need to update that
      if (space.space_id === state.currentSpace.space_id) {
        console.log("commit current space", space);
        state.currentSpace = space;
      }
      // if it's in our array of spaces we need to update that
      const index = state.spaces.findIndex(
        (item) => item.space_id === space.space_id
      );
      if (index !== -1) {
        console.log("commit space", space);

        state.spaces[index] = space;
      }
    },
    SOCKET_UPDATEEVENT(state, event) {
      console.log("SOCKET_UPDATEEVENT", event);
      // whenever event is updated by the api
      // it emits an event via sockets with the updates event
      // if it's the current event, need to update that
      if (event.event_id === state.currentEvent.event_id) {
        console.log("commit event", event);

        state.currentEvent = event;
      }
    },
    SOCKET_UPDATEROOM(state, room) {
      //220323 console.log("SOCKET_UPDATEROOM", room);

      // whenever room is updated by the api
      // it emits an event via sockets with the updates room
      // if it's the current room, need to update that
      if (room.room_id === state.currentRoom.room_id) {
        console.log("commit current room", room);
        state.currentRoom = room;
      }
      // if it's in our array of rooms we need to update that
      const index = state.roomsList.findIndex(
        (item) => item.room_id === room.room_id
      );
      if (index !== -1) {
        const roomsList = [...state.roomsList];
        roomsList[index] = room;
        state.roomsList = roomsList;
      }
    },
    SOCKET_UPDATEUSERS(state, users) {
      //220323 console.log("SOCKET_UPDATEUSERS", users);
      // whenever user is updated by the api
      // it emits an user via sockets with the updates user
      if (!state.currentUser.user_id) {
        // no point in doing anything if we have no user
        return;
      }

      // if it's the current user, need to update that
      const user = users.find((u) => u.user_id === state.currentUser.user_id);
      if (user) {
        //220323 console.log("commit current user", user);
        state.currentUser = user;
      }

      // then see if we have users for the current event
      const usersList = [...state.usersList];
      for (const u of users) {
        // if it's in our array of users we need to update that
        const index = state.usersList.findIndex(
          (item) => item.user_id === u.user_id
        );
        if (index !== -1) {
          //220323 console.log("commit user", u);
          usersList[index] = u;
        } else {
          // otherwise we need to add it
          //220323 console.log("add user", u);
          usersList.push(u);
        }
      }
      state.usersList = usersList;
    },
    SOCKET_UPDATEEARNEDKEY(state, ek) {
      // check it's for this user
      if (ek.user_id != state.currentUser.user_id) {
        return;
      }
      // check if we are updateing an existing key
      const index = state.earnedKeys.findIndex((item) => item.id == ek.id);
      if (index != -1) {
        state.earnedKeys[index] = ek;
        return;
      }
      // otherwise add new key
      state.earnedKeys.push(ek);
    },
    SOCKET_ACTIVEUSERS(state, users) {
      //220323 console.log("active users", users);
      state.eventAttendance = users;
    },
  },
  actions: {
    ...eventActions,
    ...spaceActions,
    ...roomActions,
    ...userActions,
    ...keyActions,
    ...actions,
    socket_reloadEvent({ dispatch, state }, { event_id }) {
      console.log("socket_reloadEvent", event_id);
      if (event_id == state.currentEvent.event_id) {
        dispatch("loadCurrentEventById", { event_id });
      }
    },
    socket_reloadUsers({ dispatch, state }, user_ids) {
      console.log("socket_reloadUsers", user_ids);
      for (user_id of user_ids) {
        if (user_id == state.currentEvent.user_id) {
          dispatch("loadCurrentUserById", { user_id });
        }
        // Not necessary to refersh users list
        // as this only happens when UserSpaces changes
        // which is only relevent to current user

        // if (state.usersList.find((u) => u.user_id == user_id)) {
        //   dispatch("loadUsersListForSpace", {
        //     space_id: state.currentSpace.space_id,
        //   });
        // }
        break;
      }
    },
  },
  modules: {},
});
