<template>
  <div class="PageWrapper">
    <slot />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  // will wrap every page. Will only be mounted once when page is loaded/refreshed
  // not when user navigates between Home and TheRoom
  name: "PageWrapper",
  computed: {
    ...mapState(["currentEvent", "debug", "currentUser"]),
    debugQuery() {
      return this.$route.query.debug || 0;
    },
  },
  beforeMount() {
    if (this.debugQuery != this.debug) {
      this.$store.commit("setDebug", parseInt(this.debugQuery, 10));
    }
  },
  sockets: {
    // listening to socket server connect events
    connect() {
      console.log(
        "socket connected",
        this.currentUser?.user_id,
        this.currentUser?.user_name
      );
    },
    connect_error() {
      console.error("socket connect_error");
    },
    disconnect() {
      console.log("socket disconnected");
    },
  },
  watch: {
    "currentEvent.lan": {
      handler() {
        this.$i18n.locale = this.currentEvent.lan || "en";
      },
      immediate: true,
    },
    debugQuery() {
      if (this.debugQuery != this.debug) {
        this.$store.commit("setDebug", parseInt(this.debugQuery, 10));
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
