<template>
  <div
    class="d-flex RoomNavigationUser overflow-auto theme-user-text"
    :class="{
      '--video': user.status == 2,
      '--jitsi': user.status == 1,
      '--current': current,
    }"
  >
    <i v-if="user.status == 3" class="fas fa-key" style="color: yellow"></i>
    <i
      v-else-if="user.status == 2"
      style="font-size: 0.8rem"
      class="fas fa-video"
    ></i>

    <i
      v-if="
        $store.state.currentUser.admin &&
        user.user_current_event != $store.state.currentUser.user_current_event
      "
      class="far fa-user"
    ></i>
    <i v-else-if="user.admin == 1" class="fas fa-user-check"></i>
    <i v-else-if="user.admin == 2" class="fas fa-user-plus"></i>
    <i v-else-if="user.admin == 3" class="fas fa-user-cog"></i>

    <i v-else class="fas fa-user"></i>

    {{ user.user_name }}
    <small class="pl-2"
      ><i v-for="n in numberOfKeys" :key="n" class="fas fa-key p-0"></i
    ></small>

    <template v-if="user.user_text">
      <small class="p-0 ml-2 text-right">
        {{ user.user_text }}
      </small>
    </template>

    <button
      v-if="$store.state.currentUser.admin"
      class="btn btn-link p-0 ml-2"
      @click="openSettings(user)"
    >
      <i class="fas fa-cog"></i>
    </button>
    <div
      :title="
        isActive
          ? $t('message.invite_tooltip', { person: user.user_name })
          : $t('message.invite_wait')
      "
    >
      <button
        v-if="
          $store.state.currentSpace.is_inviting_enabled === 1 &&
          user.user_current_room != $store.state.currentUser.user_current_room
        "
        class="btn btn-link p-0 ml-2"
        @click="inviteUser(user, $store.state.currentUser)"
        :disabled="!isActive && !$store.state.currentUser.admin"
      >
        <i class="fas fa-envelope"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: true,
    };
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    current: Boolean,
    numberOfKeys: { type: Number, default: 0 },
  },
  methods: {
    openSettings(user) {
      this.$emit("openSettings", user);
    },
    inviteUser(invitee, inviter) {
      // Disable button
      this.isActive = false;
      // Duration of button being disabled is set to 1 min
      const disabledTime = 1 * 60 * 1000;
      // Set timeout re-enable the button
      setTimeout(() => {
        this.isActive = true;
      }, disabledTime);

      // Emit to server "invite" event to trigger broadcast
      this.$socket.client.emit("invite", {
        invitee: {
          user_id: invitee.user_id,
          user_name: invitee.user_name,
        },
        inviter: {
          user_id: inviter.user_id,
          user_name: inviter.user_name,
        },
        room: {
          room_name: this.$store.state.currentRoom.room_name,
          room_id: inviter.user_current_room,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  padding: 2px 4px;
  opacity: 0.8;
}

.RoomNavigationUser {
  font-size: 1rem;
}
</style>
