<template>
  <div
    v-if="currentUser.admin != 0 || !eventSpace.is_hidden"
    class="SpaceNavigationItem"
    v-bind:style="[eventSpace.is_hidden ? { opacity: 0.5 } : {}]"
  >
    <!-- Button to change space -->
    <button
      class="SpaceNavigationButton btn theme-button-primary"
      :title="
        $t('message.space_change', {
          departing_space: currentSpace.space_name,
          destination_space: space.space_name,
        })
      "
      @click="enterSpace(space)"
    >
      <h4>
        <i class="fas fa-archway"></i>
        <i class="fas fa-arrow-left"></i>
        {{ space.space_name }}
      </h4>
      <div
        v-if="space.space_description"
        style="padding-top: 20px; font-size: small"
      >
        <div v-html="space.space_description"></div>
      </div>
    </button>
    <!-- Button to toggle Hide Space, only admin can do -->
    <button
      v-if="currentUser.admin >= 2"
      title="Hide / Unhide this space from users"
      class="SpaceIconButton btn theme-button-primary"
      @click="toggleHideSpace()"
    >
      <i
        class="fas"
        :class="{
          'fa-eye-slash': eventSpace.is_hidden && !hiding,
          'fa-eye': !eventSpace.is_hidden && !hiding,
          'fa-clock': hiding,
        }"
      ></i>
    </button>

    <!-- #needHelp I need to find a way to get the users for all the spaces-->
    <!-- Display Info on number of user in space -->
    <!-- <div class="SpaceIcon theme-button-primary">
      <i class="fas fa-user-friends" />
      <small style="font-size: 0.8rem; opacity: 0.8">
        {{ countUsersInSpace || 0 }}
      </small>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    space: {
      type: Object,
      default: () => ({}),
    },
    eventSpace: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      newHiddenStatus: this.eventSpace.is_hidden,
    };
  },
  computed: {
    ...mapState(["currentEvent", "currentUser", "currentSpace", "usersList"]),
    hiding() {
      return this.newHiddenStatus != this.eventSpace.is_hidden;
    },
    //to fix - query issue
    countUsersInSpace() {
      return this.usersList.filter(
        (u) => u.user_current_space == this.space.space_id
      ).length;
    },
  },
  watch: {
    "eventSpace.is_hidden"() {
      // keep in sync in case changed elsewhere
      this.newHiddenStatus = this.eventSpace.is_hidden;
    },
  },
  methods: {
    enterSpace(space) {
      this.$store.dispatch("moveToSpace", space);
    },
    toggleHideSpace() {
      if (this.hiding) {
        return;
      }
      const data = {
        space_id: this.space.space_id,
        is_hidden: this.eventSpace.is_hidden ? 0 : 1,
      };

      // Dispatch action to update database
      this.newHiddenStatus = data.is_hidden;
      this.$store
        .dispatch("updateEventSpaces", data)
        .then(() => {
          // update will come automatically through sockets
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.SpaceNavigationItem {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.SpaceNavigationButton,
.SpaceIconButton,
.SpaceIcon {
  padding: 16px;
  margin: 4px 0;
  transition: background-color 0.3s color 0.3s;
}
.SpaceNavigationButton {
  width: 100%;
  border-radius: 10px 0 0 10px;
  flex-grow: 1;
}
.SpaceIconButton {
  border-radius: 0;
}
.SpaceIcon {
  border-radius: 0 10px 10px 0;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 4px;

  small {
    transform: translateY(-8px);
  }
}

h4 {
  text-align: left;
}
h4,
i {
  font-size: 18px;
  margin: 0;
  padding: 0;
}
</style>
