<template>
  <form class="form-horizontal" @submit="createOrUpdateUser" autocomplete="off">
    <div v-if="showExistingUser" class="mb-3">
      <h3>
        <span> {{ $t("message.login_welcome_back") }} </span>
        {{ selectedUser.user_name }}
      </h3>
    </div>
    <div v-else class="form-group">
      <label for="user_name" class="control-label">
        {{ fields.user_name_label }}
      </label>
      <input
        maxlength="30"
        id="user_name"
        class="form-control"
        :placeholder="fields.user_name_placeholder"
        name="user_name"
        required="true"
        v-model="newUser.user_name"
      />
    </div>
    <div class="form-group" v-if="fields.user_metadata">
      <label for="mood_field" class="control-label mood-field">
        {{ fields.user_metadata_label }}
      </label>
      <label for="mood_field" class="control-label label-tip">
        {{ fields.user_metadata_label_tip }}
      </label>
      <input
        ref="userTextInput"
        maxlength="42"
        id="mood_field"
        class="form-control"
        rows="1"
        :placeholder="fields.user_metadata"
        name="target_user_text"
        v-model="newUser.user_text"
      />
    </div>
    <div v-if="backstage" class="form-group">
      <label for="access_code" class="control-label">
        {{ $t("message.login_password_prompt") }}
      </label>
      <input
        maxlength="30"
        id="access_code"
        class="form-control"
        name="access_code"
        required="true"
        v-model="newUser.access_code"
      />
    </div>

    <div class="form-group submit">
      <button type="submit" class="btn btn-primary">
        <i class="fas fa-sign-in-alt"></i>
        {{ fields.submit_value }}
      </button>
    </div>
    <p v-if="showExistingUser">
      <br />
      <span> {{ $t("message.login_not_you") }} </span>
      <br />
      <button
        ref="resetUserButton"
        class="btn btn-secondary"
        type="text"
        @click="resetUser"
      >
        <i class="fas fa-user-slash"></i>
        <span> {{ $t("message.login_new_user") }} </span>
      </button>
    </p>
    <BSpinner v-if="submittingUser" />
    <p v-if="formError">{{ formError }}</p>
  </form>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    BSpinner,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    backstage: Boolean,
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const result = {
      newUser: {
        user_name: "",
        user_text: "",
        access_code: "",
      },
      submittingUser: false,
      reset: false,
      formError: "",
    };
    return result;
  },
  computed: {
    ...mapState(["currentEvent"]),
    showExistingUser() {
      return !!(!this.reset && this.user?.user_id);
    },
    selectedUser() {
      if (this.reset || !this.user?.user_id) {
        return this.newUser;
      }
      return this.user;
    },
  },
  methods: {
    resetUser() {
      this.newUser = {
        user_name: "",
        user_text: "",
        access_code: "",
      };
      this.reset = true;
    },
    createOrUpdateUser(e) {
      if (e) {
        e.preventDefault();
      }

      const data = this.selectedUser;
      const isUpdate = !!data.user_id;
      if (!data || !isUpdate) {
        data.user_name = this.newUser.user_name;
      }
      if (!data.user_name) {
        this.formError = "Please enter user name";
        return;
      }

      data.user_text = this.newUser.user_text;
      data.access_code = this.newUser.access_code;
      data.user_current_room = this.currentEvent.room_id;
      data.user_current_space = this.currentEvent.initial_space;
      data.user_current_event = this.currentEvent.event_id;
      data.admin = false;

      this.submittingUser = true;
      this.$store
        .dispatch("loginUser", data)
        .then((user) => {
          const user_id = data.user_id || user.user_id;
          console.log("set local storage", user_id);
          localStorage.setItem(
            "agora",
            JSON.stringify({ user_id, created: new Date() })
          );
          let path = "/experience";
          this.$router.push({ path, query: this.$route.query });
        })
        .catch((err) => {
          this.formError = err;
        })
        .finally(() => {
          this.submittingUser = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.submit {
  padding-top: 8px;
  margin-bottom: 8px;
}

.mood-field {
  margin: 10px 0 0;
}

.label-tip {
  font-size: 0.8em;
  color: gray;
  display: block;
}
</style>
