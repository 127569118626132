<template>
  <div v-if="!debug" ref="jitsiContainer" id="jitsiContainer" />
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      jitsiApi: null,
      jitsiRoomID: 0,
      jitsiUserID: 0,
    };
  },
  computed: {
    ...mapState([
      "currentUser",
      "currentRoom",
      "currentSpace",
      "loadingJitsi",
      "debug",
    ]),
    jitsi_domain() {
      var jitsi_domain = "nube.atypiclabs.com"; //default server, if none specified anywhere
      if (this.currentRoom.alt_jserver) {
        jitsi_domain = this.currentRoom.alt_jserver;
      } else if (this.currentSpace.alt_jserver) {
        jitsi_domain = this.currentSpace.alt_jserver;
      }
      return jitsi_domain;
    },
    current_experience_mode() {
      if (this.currentRoom.experience_mode) {
        return this.currentRoom.experience_mode;
      } else {
        return this.currentSpace.d_experience_mode;
      }
    },
  },
  mounted() {
    this.loadJitsi();
  },
  watch: {
    jitsi_domain(newValue) {
      this.loadJitsi(); //if the jitsi domain changed, reload Jitsi
    },
    currentUser(newuser, olduser) {
      if (olduser.user_name !== newuser.user_name) {
        this.updateJitsiUserName(newuser.user_name);
      }
    },
    currentRoom() {
      this.reloadJitsiIfRoomChanged();
    },
    debug() {
      if (this.debug == 0) {
        this.loadJitsi();
      }
    },
  },
  beforeDestroy() {
    if (this.loadingJitsi) {
      this.$store.commit("setBoolean", {
        prop: "loadingJitsi",
        value: false,
      });
    }
    if (this.jitsiApi) {
      this.jitsiApi.dispose();
    }
  },
  methods: {
    updateJitsiUserName(name) {
      if (this.jitsiApi) {
        this.jitsiApi.executeCommand("displayName", name);
      }
    },
    reloadJitsiIfRoomChanged() {
      if (!this.jitsiApi || this.currentRoom.room_id !== this.jitsiRoomID) {
        //console.log(          "Jitsi room changed from",
        //  this.jitsiRoomID,
        //  "to",
        //  this.currentRoom.room_id
        //);
        this.loadJitsi();
      }
    },
    loadJitsi() {
      // if jitsi is in process of loadiing, don't do anything
      if (this.loadingJitsi) {
        return;
      }
      // if jitsi already exists, first remove it
      if (this.jitsiApi) {
        this.jitsiApi.dispose();
        this.jitsiApi = null;
      }
      if (!this.currentUser.user_name || !this.currentRoom.room_id) {
        //console.error("invalid data for jitsi");
        return;
      }
      if (this.debug === 1 || this.debug === 2) {
        //console.log("skipping jitsi for debugging");
        return;
      }

      this.$store.commit("setBoolean", {
        prop: "loadingJitsi",
        value: true,
      });
      this.jitsiUserID = this.currentUser.user_id;
      this.jitsiRoomID = this.currentRoom.room_id;

      this.jitsiApi = new JitsiMeetExternalAPI(
        this.jitsi_domain,
        this.getOptions(this.currentUser, this.currentRoom)
      );
    },
    getOptions(currentUser, currentRoom) {
      const options = {
        width: "100%",

        currentUser: {
          displayName: currentUser.user_name,
        },
        roomName: `${currentRoom.room_id}-c8cfdb0d-135a-4462-a05b-36575c3ef591`,
        parentNode: this.$refs.jitsiContainer,
        configOverwrite: {
          SHOW_JITSI_WATERMARK: false, //this is not working anymore - need to edit directly on the server: /usr/share/jitsi-meet-web-config/config.js

          DISABLE_VIDEO_BACKGROUND: true,
          disableAudioLevels: false,
          //startWithAudioMuted: true,

          enableNoisyMicDetection: false,
          enableClosePage: false,
        },
        onload: () => {
          //console.log("jitsi ready");
          this.$store.commit("setBoolean", {
            prop: "loadingJitsi",
            value: false,
          });
          // Jitsi doesn't seem to be setting the username correctly when initialising it.
          // So here we are resetting it
          this.updateJitsiUserName(currentUser.user_name);
          this.reloadJitsiIfRoomChanged();
        },
      };

      //        }

      if (this.currentUser.admin == 1 || this.currentUser.admin == 2) {
        options.interfaceConfigOverwrite = {
          DISABLE_FOCUS_INDICATOR: true,
          SHOW_CHROME_EXTENSION_BANNER: false,
          DEFAULT_REMOTE_DISPLAY_NAME: "Atypic admin",
          TOOLBAR_BUTTONS: [
            "desktop",
            "fullscreen",
            "sharedvideo",
            "stats",
            "mute-everyone",
            "microphone",
            "camera",
            "fodeviceselection",
            "chat",
            "settings",
            "videoquality",
            "filmstrip",
            "shortcuts",
            "tileview",
          ],
        };
      } else if (this.currentUser.admin == 3) {
        //super user
        options.interfaceConfigOverwrite = {
          DISABLE_FOCUS_INDICATOR: true,
          SHOW_CHROME_EXTENSION_BANNER: false,
          DEFAULT_REMOTE_DISPLAY_NAME: "Atypic admin",
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "closedcaptions",
            "desktop",
            "fullscreen",
            "fodeviceselection",
            "hangup",
            "profile",
            "info",
            "chat",
            "recording",
            "livestreaming",
            "etherpad",
            "sharedvideo",
            "settings",
            "raisehand",
            "videoquality",
            "filmstrip",
            "invite",
            "feedback",
            "stats",
            "shortcuts",
            "tileview",
            "videobackgroundblur",
            "download",
            "help",
            "mute-everyone",
            "e2ee",
          ],
        };
      } else {
        options.interfaceConfigOverwrite = {
          DISABLE_FOCUS_INDICATOR: true,
          SHOW_CHROME_EXTENSION_BANNER: false,
          DEFAULT_REMOTE_DISPLAY_NAME: "Atypic guest",
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "fodeviceselection",
            "chat",
            "filmstrip",
            "tileview",
          ],
          SETTINGS_SECTIONS: ["devices", "language"],
        };
      }

      if (this.current_experience_mode == "cabaret1") {
        options.interfaceConfigOverwrite.TILE_VIEW_MAX_COLUMNS = 1;
      }

      if (this.current_experience_mode == "social_stage") {
        options.interfaceConfigOverwrite.TILE_VIEW_MAX_COLUMNS = 2;
        options.configOverwrite.startWithAudioMuted = false;
        //options.configOverwrite.startWithAudioMuted = true;
      } //2=cabaret double column

      if (this.current_experience_mode == "socialmuted_stage") {
        options.interfaceConfigOverwrite.TILE_VIEW_MAX_COLUMNS = 2;
        options.configOverwrite.startWithAudioMuted = true;
        //command works when selected explicitly on room
      }

      if (this.current_experience_mode == "social") {
        //options.configOverwrite.startWithAudioMuted = false;
      }
      if (this.current_experience_mode == "socialmuted") {
        options.configOverwrite.startWithAudioMuted = true;
      }
      if (this.current_experience_mode == "mill") {
        options.configOverwrite.startSilent = true; //temp - in this experience we wanted all mics to be closed with no option to unmute
      }

      //console.log("Options object sent to jitsi: ", options);

      return options;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<!-- complete toolbar options

          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "closedcaptions",
            "desktop",
            "fullscreen",
            "fodeviceselection",
            "hangup",
            "profile",
            "info",
            "chat",
            "recording",
            "livestreaming",
            "etherpad",
            "sharedvideo",
            "settings",
            "raisehand",
            "videoquality",
            "filmstrip",
            "invite",
            "feedback",
            "stats",
            "shortcuts",
            "tileview",
            "videobackgroundblur",
            "download",
            "help",
            "mute-everyone",
            "e2ee",
          ],


-->

<!--
SERVER NOTES: see /jitsi_server_notes.txt at the root of the git repository
-->
