<template>
  <div class="RoomNavigationHeader text-center p-3 theme-background-primary">
    <div class="py-2">
      <h4 class="mb-0 py-2">
        <i
          v-if="currentRoom.is_locked == 1 || currentRoom.is_locked == 3"
          class="fas fa-lock"
        />
        <b>{{ currentRoom.room_name }}</b>
        <br />
        <span v-if="!showUserList">
          <sup>
            <i class="fas fa-users" />
            {{ usersInRoom ? usersInRoom.length : 0 }}
          </sup>
        </span>
      </h4>
      <div v-if="currentRoom.room_text && usersInRoom.length > 0">
        <small class="room_text overflow-auto">
          {{ currentRoom.room_text }}
        </small>
      </div>
    </div>
    <div class="py-2 text-left">
      <!-- Current Room Description -->
      <div
        v-if="currentRoom.room_description"
        v-html="currentRoom.room_description"
        class="py-2 text-left"
        style="font-size: 1rem"
      />

      <!-- All Users in the room, except if room is set to hide list, or if the user is an admin-->
      <div class="RoomNavigation_userlist" v-if="showUserList">
        <RoomNavigationUser
          v-for="(user, i) in usersInRoom"
          :key="i"
          :user="user"
          :current="user.user_id == currentUser.user_id"
          :numberOfKeys="keysForUser(user.user_id)"
          @openSettings="openSettings"
        />
      </div>
    </div>
    <!-- Greeting -->
    <div class="px-3 pt-3">
      <template v-if="!currentRoom.trap || currentUser.admin" class="prompt">
        <h5 class="prompt" v-if="currentEvent.greeting">
          {{ currentEvent.greeting }}
        </h5>
        <h5 class="prompt" v-else>Where do you want to go next?</h5>
      </template>
      <h5 v-else class="prompt">There's nowhere to go...</h5>
    </div>
  </div>
</template>

<script>
import RoomNavigationUser from "@/components/RoomNavigation/RoomNavigationUser.vue";
import { mapState } from "vuex";

export default {
  components: {
    RoomNavigationUser,
  },
  computed: {
    ...mapState([
      "currentRoom",
      "currentUser",
      "currentEvent",
      "keys",
      "usersList",
      "earnedKeys",
      "eventAttendance",
    ]),
    usersInRoom() {
      return this.usersList.filter(
        (u) =>
          u.user_current_room == this.currentRoom.room_id &&
          this.eventAttendance.includes(u.user_id)
      );
    },
    showUserList() {
      //show user list if not hidden or admin2
      return (
        //this.usersInRoom.length > 0 &&
        this.currentRoom.hide_participant_list == 0 ||
        this.currentUser.admin >= 2
      );
    },
  },
  methods: {
    keysForUser(user_id) {
      // only show keys for current user
      if (user_id != this.currentUser.user_id) {
        return 0;
      }
      if (!this.earnedKeys) {
        return 0;
      }
      const keys = this.earnedKeys.filter((k) => {
        return k.claimed;
      });
      // only show if you don't have full set
      if (keys.length < this.keys.length) {
        return keys.length;
      }
      return 0;
    },
    openSettings(user) {
      this.$emit("userSettings", user);
    },
  },
};
</script>

<style lang="scss" scoped>
.RoomNavigationHeader {
  border-radius: 10px;
  margin: 2px;
}
.RoomNavigation {
  &_userlist {
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.current-room {
  position: relative;
  margin: 0;
  padding-top: 21px;
  opacity: 1 !important; //hack to make the header look different than the other buttons
  border: none !important;
}

// Icons
i {
  padding-right: 16px;
  opacity: 0.8;
}

.room_text {
  font-size: 1rem;
  text-align: left;
  margin-top: 16px;
  max-height: 100px;
}
</style>
