<template>
  <article>
    <h1>SSSTaR welcomes you to the Mysteries of the Astral Portal!</h1>

    <p>
      Whether you want to attend an Event or Gathering within or to join the
      “Quest of the Keys” into Self-Discovery and Initiation, you need to:
    </p>

    <ul>
      <li>
        Enter the Portal via a COMPUTER running Chrome (other browsers and
        tablets may not work and, although phones do, we strongly recommend
        against it as it is a less immersive experience).
      </li>
      <li>
        Enter this sacred space with an open mind and open heart...to meet new
        people and try new things.
      </li>
      <li>
        To get involved with SSSTaR, get involved in the Portal, or provide
        feedback, please join our
        <a href="https://www.facebook.com/groups/1217088291764026"> FB group</a>
      </li>
    </ul>
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped></style>
