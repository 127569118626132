import axios from "axios";
import { nodeserver } from "../../agora-conf.json";

const nodeApiUrl = `${nodeserver.protocol}${nodeserver.host}${
  nodeserver.port ? `:${nodeserver.port}` : ""
}${nodeserver.apidir}`;

const errorLog = (err) => {
  if (err.response) {
    if (err.response.data) {
      console.error(err.response.data);
      return;
    }
    console.error(err.response);
    return;
  }
  console.error(err);
};

const actions = {
  loadAllData({ dispatch }, user_id) {
    // load user, room, and space, from user_id
    let user;
    let event;
    return new Promise((resolve, reject) => {
      dispatch("loadCurrentUserById", { user_id })
        .then((res) => {
          user = res;
          return dispatch("loadCurrentEventById", {
            event_id: user.user_current_event,
          });
        })
        .then((res) => {
          event = res;
          return Promise.all([
            dispatch("loadCurrentRoomById", {
              room_id: user.user_current_room,
            }),
            dispatch("loadCurrentSpaceById", {
              // also loads users list and rooms list
              space_id: user.user_current_space,
            }),
          ]);
        })
        .then((res) => {
          const room = res[0];
          const space = res[1];
          resolve({ user, room, space, event });
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  accessAstralPlane({ dispatch }, user) {
    return new Promise((resolve, reject) => {
      Promise.all([
        dispatch("giveUserAccessToSpace", {
          user: user,
          space_id: 2132, // astral plane
        }),
        ,
        dispatch("giveUserAccessToSpace", {
          user: user,
          space_id: 2152, // astral temple
        }),
        ,
        dispatch("giveUserAccessToSpace", {
          user: user,
          space_id: 12345620, // Cabaret de l'Amour
        }),
      ])
        .then(() => {
          return dispatch("teleportUserToRoom", {
            user_id: user.user_id,
            room_id: 20072650,
            space_id: 2132,
          });
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        })
        .then(() => {
          resolve();
        });
    });
  },

  teleport({ commit, state, dispatch }, room_id) {
    // This sets the user_current_room for the currentUser
    // While this is happening - teleporting is set to true
    // Then set the user_current_room of the currentUser in the store
    // Also checks if the room is locked and unlocks it if it's empty
    return new Promise((resolve, reject) => {
      console.log("teleport", room_id);
      commit("setBoolean", { prop: "teleporting", value: true });
      axios
        // first check if room is empty and update lock status
        .put(`${nodeApiUrl}/rooms/${room_id}/empty_unlocker`)
        // then move user to room
        .then(() => {
          dispatch("updateUser", {
            user_id: state.currentUser.user_id,
            user_current_room: room_id,
          })
            .then((res) => {
              commit("setBoolean", { prop: "teleporting", value: false });
              resolve(res);
            })
            .catch((err) => {
              errorLog(err);
              commit("setBoolean", { prop: "teleporting", value: false });
              reject(err);
            });
        })
        .catch((err) => {
          errorLog(err);
          commit("setBoolean", { prop: "teleporting", value: false });
          reject(err);
        });
    });
  },

  giveUserAccessToSpace({}, { user, space_id }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${nodeApiUrl}/user_spaces/`, { user_id: user.user_id, space_id })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          errorLog(err);
          reject();
        });
    });
  },

  giveAccessAndSendGroupUsersToSpace({ dispatch }, { space_id, users }) {
    return new Promise((resolve, reject) => {
      const usersData = users.map((u) => {
        return {
          space_id,
          user_id: u.user_id,
        };
      });
      Promise.all([
        // add spacesto all users
        axios.post(`${nodeApiUrl}/user_spaces/batch`, usersData),
        // get space to find default room
        axios.get(`${nodeApiUrl}/spaces/${space_id}`),
      ])
        .then((res) => {
          // send users to space and default room
          const space = res[1].data;
          return dispatch(
            "updateUsersBatch",
            users.map((u) => u.user_id),
            [
              { prop: "user_current_room", value: space.default_room },
              { prop: "user_current_space", value: space_id },
            ]
          );
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
};

export default actions;
