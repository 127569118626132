<template>
  <article>
    <p>
      Welcome. Let's create some magic.
      <br />
    </p>

    <h1>Artist's suggestions:</h1>
    <p></p>
    <ul>
      <li>
        For best experience - please use your desktop.
        <b>Safari browser won't work </b> (for now)
      </li>
      <li>
        Your browser will prompt you to give video and audio permissions. Simply
        click allow.
      </li>
      <li>Use the secret rooms to exchange with other artists, or to test</li>
      <li>Keep your video on in Toggle Tile View (gallery view).</li>
    </ul>
    <p></p>
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped></style>
