<template>
  <!-- SPECIAL CASE: Section title - a disabled button that works as a Title-->
  <div v-if="room.experience_mode == 'title'" class="RoomNavigationItem_Title">
    <BButton
      v-if="currentUser.admin != 0"
      class="btn"
      :class="{ '--unclickable': updatingHidden }"
      @click="toggleHideRoom"
    >
      <span v-if="updatingHidden"><i class="fas fa-ellipsis-h"> </i></span>
      <span v-else
        ><i
          class="fas"
          :class="{ 'fa-eye-slash': room.hidden, 'fa-eye': !room.hidden }"
        >
        </i
      ></span>
    </BButton>
    {{ room.room_name }} 
    <div v-if="room.room_description"> <small> {{ room.room_description }} </small> </div>
  </div>
  <!-- NORMAL BUTTON STARTS HERE -->
  <div
    v-else
    class="RoomNavigationItem"
    :class="{ '--full': roomFull, '--hidden': room.hidden }"
  >
    <div class="RoomNavigationItem_Buttons">
      <BButton
        class="
          RoomNavigationItem_Button
          theme-button-secondary
          btn btn-link
          --teleport
          --left
        "
        :disabled="(roomLocked || roomFull) && !currentUser.admin"
        :class="{ '--disabled': roomLocked || roomFull }"
        @click="selectRoom"
      >
        <div class="d-flex flex-wrap justify-content-between">
          <div>
            <i v-if="roomLocked" class="fas fa-lock"></i>
            <b>{{ room.room_name }}</b>
          </div>
        </div>
        <div v-if="room.room_text && usersInRoom.length > 0" class="text-left">
          <small> {{ room.room_text }} </small>
        </div>
      </BButton>
      <div class="RoomNavigationItem_right">
        <!-- <BButton
          v-if="currentUser.admin"
          class="RoomNavigationItem_Button theme-button-secondary btn btn-link --middle"
          @click="roomAdminSettings(room)"
        >
          <i class="fas fa-cog" style="color: #007bff"></i>
        </BButton> -->

        <BButton
          v-if="room.room_description"
          class="
            RoomNavigationItem_Button
            theme-button-secondary
            btn btn-link
            --middle
          "
          :class="{ collapsed_info: !expanded_info }"
          @click="expanded_info = !expanded_info"
        >
          <span><i class="fas fa-info-circle"></i></span>
        </BButton>

        <BButton
          v-if="currentUser.admin != 0"
          class="
            RoomNavigationItem_Button
            theme-button-secondary
            btn btn-link
            --middle
          "
          :class="{ '--unclickable': updatingHidden }"
          @click="toggleHideRoom"
        >
          <span v-if="updatingHidden"><i class="fas fa-ellipsis-h"> </i></span>
          <span v-else
            ><i
              class="fas"
              :class="{
                'fa-eye-slash': room.hidden == 1,
                'fa-eye': !room.hidden,
              }"
            >
            </i
          ></span>
        </BButton>

        <BButton
          class="
            RoomNavigationItem_Button
            theme-button-secondary
            btn btn-link
            --right
          "
          :class="{
            collapsed_users: !expanded_users,
            '--unclickable': usersInRoom.length < 1,
          }"
          @click="expanded_users = !expanded_users"
        >
          <div class="ml-auto">
            <i v-if="room.hide_participant_list == 1" class="fas fa-users" />
            <i v-else class="fas fa-user-friends" />
            <sup>
              {{ usersInRoom ? usersInRoom.length : 0 }}
            </sup>
            <span v-if="roomAlmostFull">
              / {{ room.max_capacity || currentSpace.max_capacity }}*
            </span>
            <small v-else-if="roomFull"> FULL</small>
          </div>
        </BButton>
      </div>
    </div>

    <div
      v-if="showUserList || showRoomDescription"
      class="Room_SidePanel_Box_Section_Content theme-background-translucent"
      :class="{
        '--content': room.room_description || usersInRoom.length > 0,
      }"
    >
      <BCollapse v-model="expanded_info">
        <div class="Room_SidePanel_Box_Section_Content_Text">
          <div v-if="room.room_description" v-html="room.room_description" />
        </div>
      </BCollapse>

      <BCollapse v-model="expanded_users">
        <div
          v-if="showUserList"
          class="Room_SidePanel_Box_Section_Content_Active_Users"
        >
          <RoomNavigationUser
            v-for="(user, i) in usersInRoom"
            :key="i"
            :user="user"
            @openSettings="openSettings"
          />
        </div>
      </BCollapse>
    </div>
  </div>
</template>

<script>
import { BCollapse, BButton } from "bootstrap-vue";
import RoomNavigationUser from "@/components/RoomNavigation/RoomNavigationUser.vue";
import { mapState } from "vuex";

export default {
  components: {
    BCollapse,
    BButton,
    RoomNavigationUser,
  },
  props: {
    room: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      expanded_info: true, //PROBLEM this won't work with false until we find a better way to deal with current users on the header, or to pre-expand the current room
      expanded_users: true, //PROBLEM this won't work with false until we find a better way to deal with current users on the header, or to pre-expand the current room
      newHiddenStatus: this.room.hidden,
    };
  },
  computed: {
    ...mapState([
      "currentRoom",
      "currentUser",
      "currentSpace",
      "usersList",
      "earnedKeys",
      "eventAttendance",
    ]),
    showUserList() {
      //show user list if not hidden or admin2
      return (
        this.usersInRoom.length > 0 &&
        (this.room.hide_participant_list == 0 || this.currentUser.admin >= 2)
      );
    },

    showRoomDescription() {
      //show info box if there's a room description or user list
      return this.room.room_description;
    },
    usersInRoom() {
      // get users in this room
      // and remove users that have disconnected
      return this.usersList.filter(
        (u) =>
          u.user_current_room == this.room.room_id &&
          this.eventAttendance.includes(u.user_id)
      );
    },
    updatingHidden() {
      return this.newHiddenStatus !== this.room.hidden;
    },
    roomLocked() {
      return (
        this.room.is_locked == 3 ||
        (this.room.is_locked == 1 && this.usersInRoom.length > 0)
      );
    },
    roomFull() {
      var room_capacity = 9; //default room capacity, if not specified on database
      if (this.currentSpace.max_capacity) {
        room_capacity = this.currentSpace.max_capacity;
      }
      if (this.room.max_capacity > 0) {
        room_capacity = this.room.max_capacity;
      }
      return this.usersInRoom && this.usersInRoom.length >= room_capacity; //Decide if room is full
    },
    roomAlmostFull() {
      //hack: this is a bit redundant with roomFull. default room_capacity is defined on two functions
      var room_capacity = 9; //default room capacity, if not specified on database
      if (this.currentSpace.max_capacity) {
        room_capacity = this.currentSpace.max_capacity;
      }
      if (this.room.max_capacity > 0) {
        room_capacity = this.room.max_capacity;
      }
      return this.usersInRoom && this.usersInRoom.length >= room_capacity * 0.6; //Room 60% full
    },
    keys() {
      if (!this.earnedKeys) {
        return [];
      }
      return this.earnedKeys.filter((k) => {
        return k.room_id == this.room.room_id;
      });
    },
  },
  watch: {
    "room.hidden"() {
      this.newHiddenStatus = this.room.hidden;
    },
  },
  methods: {
    selectRoom() {
      this.$store.dispatch("teleport", this.room.room_id);
    },
    openSettings(user) {
      this.$emit("userSettings", user);
    },
    // roomAdminSettings(room) {
    //   this.$emit("roomAdminSettings", room);
    // },
    toggleHideRoom(e) {
      e.preventDefault();
      this.newHiddenStatus = !this.room.hidden;
      this.$store
        .dispatch("hideRoom", {
          hide_status: !this.room.hidden,
          room_id: this.room.room_id,
        })
        .then(() => {
          //this.showSuccess("hide_room");
        });
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  padding: 0 4px;
  opacity: 0.8;
}

.RoomNavigationItem {
  margin-bottom: 4px;
  // order: 2;
  &.--hidden {
    opacity: 0.5;
  }
  &.--full {
    order: 1;
  }
  &_Title {
    display: block;
    padding: 8px 8px;
    font-size: 1.21em;
  }
  &_right {
    display: flex;
  }
  &_Button {
    display: block;
    padding: 8px 8px;
    border: none;
    border-radius: 10px; /* changed all border-radius from 24 to 10 */
    align-items: center;
    position: relative;
    z-index: 1;
    text-align: left;
    &:hover {
      text-decoration: none;
    }
    &.--unclickable {
      pointer-events: none;
      cursor: not-allowed;
    }
    span {
      display: inline-block;
    }
    &.--left {
      width: 100%;
      border-right-width: 1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.--right {
      width: auto;
      flex-shrink: 1;
      white-space: nowrap;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-right: 4px;
    }
    &.--middle {
      border-radius: 0;
    }
  }
  &_Buttons {
    display: flex;
    margin: 0 8px;
  }
}
.Room_SidePanel_Box_Section_Content {
  margin: 0 16px 0;
  padding: 1px 10px 3px;
  overflow-y: auto;

  &.--content {
    border-bottom: 4px solid black;
    box-shadow: 2px 2px 10 black;
    display: block;
    overflow: auto;
  }
  &_Active_Users {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  &_Text {
    margin-bottom: 0px;
  }
}
</style>
