<template>
  <BModal ok-only size="lg" scrollable ref="roomAdminModal" @hidden="room = {}">
    <div v-if="isImage">
      <img
        class="agenda_img"
        :src="$store.state.imgdir + '/' + currentSpace.agenda_content"
      />
    </div>
    <div
      v-else-if="isWeb"
      style="height: 50%; transform: scale(0.5); transform-origin: top left"
    >
      <iframe
        :src="currentSpace.agenda_content"
        width="200%"
        height="2800px"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        allow="autoplay; fullscreen; camera *;   microphone *"
        >Loading…</iframe
      >
    </div>
  </BModal>
</template>

<script>
import {
  BModal,
  // BFormGroup,
  // BFormRadioGroup,
  // BForm,
  // BFormInput,
  // BButton,
} from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    BModal,
    // BFormGroup,
    // BFormRadioGroup,
    // BForm,
    // BFormInput,
    // BButton,
  },
  data() {
    return {
      room: {},
      eventStatusSelected: "",
      spaceModeSelected: "",
      formInfo: {
        announcement: "",
      },
      formInfo2: {
        target_room_text2: "",
      },
      success: {
        target_room_text2: false,
      },
      options_event_status: [
        { text: "Upcoming event", value: "soon" },
        { text: "Ongoing now", value: "now" },
        { text: "Finished event", value: "finished" },
      ],
      options_space_mode: [
        { text: "Stage Only", value: "stage_only" },
        { text: "Website + Stage", value: "website_stage" },
        { text: "Social Muted + Stage", value: "cabaret2muted" },
        { text: "Social + Stage", value: "cabaret2" },
        { text: "Brief Announce (Stage + Networking)", value: "announcement" },
        { text: "Social Only", value: "room_only" },
      ],
      options_menu_collapse_mode: [
        { text: "Show Menu", value: 0 },
        { text: "Hide Menu", value: 1 },
      ],
    };
  },
  computed: {
    ...mapState([
      "currentUser",
      "currentEvent",
      "currentSpace",
      "usersList",
      "eventAttendance",
    ]),
    isImage() {
      if (
        this.currentSpace.agenda_content?.includes(".jpg") ||
        this.currentSpace.agenda_content?.includes(".png")
      ) {
        return true;
      } else {
        return false;
      }
    },
    isWeb() {
      if (
        this.currentSpace.agenda_content?.includes("http://") ||
        this.currentSpace.agenda_content?.includes("https://")
      ) {
        return true;
      } else {
        return false;
      }
    },
    usersInRoom() {
      return this.usersList.filter(
        (u) =>
          u.user_current_room == this.room.room_id &&
          this.eventAttendance?.includes(u.user_id)
      );
    },
    otherUsers() {
      if (!this.usersInRoom?.length) {
        return [];
      }
      return this.usersInRoom.filter((u) => {
        return u.user_id !== this.currentUser.user_id;
      });
    },
  },
  mounted() {
    // Align space_mode with store value on mount
    this.spaceModeSelected = this.currentSpace.d_experience_mode;
    this.eventStatusSelected = this.currentEvent.event_status;
  },
  watch: {
    // Keep this selections aligned with store values upon changes
    "currentEvent.event_status"() {
      this.eventStatusSelected = this.currentEvent.event_status;
    },
    "currentSpace.d_experience_mode"() {
      this.spaceModeSelected = this.currentSpace.d_experience_mode;
    },
    "currentSpace.is_menu_collapsed_globally"() {
      this.navCollapseModeSelected =
        this.currentSpace.is_menu_collapsed_globally;
    },
  },
  methods: {
    // Submit Announcement
    submitAnnouncement(e) {
      e.preventDefault();
      const data = {
        space_id: this.currentSpace.space_id,
        tmp_message: this.formInfo.announcement,
      };
      // Emit to server to trigger broadcast
      this.$socket.client.emit("announcementfromclient", data);

      // Clear form
      this.formInfo.announcement = "";
    },
    // Update Menu Collapse Mode for all users in this space
    expandNavForAll() {
      this.$socket.client.emit("expandNavForSpace", this.currentSpace.space_id);
    },
    collapseNavForAll() {
      this.$socket.client.emit(
        "collapseNavForSpace",
        this.currentSpace.space_id
      );
    },
    // Update Space Mode for all users in this space
    updateSpaceMode() {
      const data = {
        space_id: this.currentSpace.space_id,
        d_experience_mode: this.spaceModeSelected,
      };

      this.$store.dispatch("updateSpace", data);
    },

    // Update Event Status for all users
    updateEventStatus() {
      const data = {
        event_id: this.currentEvent.event_id,
        event_status: this.eventStatusSelected,
      };

      this.$store.dispatch("updateEvent", data);
    },
    show(room) {
      if (!room) {
        console.warn("No room given");
        return;
      }
      this.room = room;
      this.$refs.roomAdminModal.show();
    },
    sendToSpace(space_id) {
      this.$store
        .dispatch("giveAccessAndSendGroupUsersToSpace", {
          space_id,
          users: this.otherUsers,
        })
        .catch()
        .then(this.$refs.roomAdminModal.hide());
    },

    showSuccess(field) {
      this.success[field] = true;
      if (this.formInfo[field]) {
        this.formInfo[field] = "";
      }
      this.interval = setInterval(() => {
        this.success[field] = false;
      }, 10000);
    },
  },
};
</script>

<style lang="scss" scoped>
.agenda {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  &_img {
    width: 100%;
    object-fit: cover;
    transition: opacity 2s;
    &.--loaded {
      opacity: 1;
    }
  }
}
</style>
