<template>
  <div
    class="embed-responsive embed-responsive-16by9"
    style="overflow: hidden; padding-top: 0%; position: relative"
  >
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSeXvPQFniYk9qsZBo9azm1Dg77eE2YUb7FmZph_ClSZbV1t1g/viewform?embedded=true"
      width="100%"
      height="300%"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      >Loading…</iframe
    >
  </div>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped></style>
