<template>
  <article>
    <h2>Welcome to The Mill, and to the world of Emotional Imaging.</h2>
    <br />
    <p>
      You will be asked to permit access to your camera so that we may create a
      live sense of community during the show. Your image will be blurred so as
      to render you unrecognizable. We will not access your microphone.
      Permission is entirely at your discretion and will not affect the quality
      of your experience. Following the performance, you are invited to remain
      online to ask questions of the creators.
    </p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 32px;

  @media (max-width: $bp-min-md) {
    font-size: 24px;
  }

  @media (max-width: $bp-min-sm) {
    font-size: 20px;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
