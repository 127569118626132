<template>
  <div
    class="embed-responsive embed-responsive-16by9"
    style="overflow: hidden; padding-top: 0%; position: relative"
  >
    <iframe
      :src="twitch_embed_url"
      height="360"
      width="1280"
      frameborder="0"
      scrolling="no"
      allowfullscreen="true"
    />
  </div>
</template>

<script>
export default {
  props: {},
  computed: {
    source_parameter() {
      if (this.$store.state.currentRoom.source_parameter) {
        return this.$store.state.currentRoom.source_parameter;
      } else {
        return this.$store.state.currentSpace.source_parameter;
      }
    },
    twitch_embed_url() {
      var twitch_channel = "atypiclabs";
      if (this.source_parameter) {
        twitch_channel = this.source_parameter;
      }
      var return_url =
        "https://player.twitch.tv/?channel=" +
        twitch_channel +
        "&parent=agora.atypiclabs.com&muted=true";
      return return_url;
    },
  },
};
</script>

<style lang="scss" scoped></style>
