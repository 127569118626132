import axios from "axios";
import { nodeserver } from "../../agora-conf.json";

const nodeApiUrl = `${nodeserver.protocol}${nodeserver.host}${
  nodeserver.port ? `:${nodeserver.port}` : ""
}${nodeserver.apidir}`;

const errorLog = (err) => {
  if (err.response) {
    if (err.response.data) {
      console.error(err.response.data);
      return;
    }
    console.error(err.response);
    return;
  }
  console.error(err);
};

const eventActions = {
  loadCurrentEventById({ commit, dispatch }, { event_id }) {
    // sets the current event from an id
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/events/${event_id}`)
        .then((res) => {
          const event = res.data;
          commit("setCurrentEvent", event);
          // whenever we load an event, we also need to load Spaces
          dispatch("loadSpacesForUserAndEvent");
          resolve(event);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
  loadCurrentEventBySlug({ commit }, { slug }) {
    // sets the current event from an url slug
    console.log("load from slug", slug);
    commit("setBoolean", {
      prop: "loadingEvent",
      value: true,
    });
    return new Promise((resolve, reject) => {
      axios
        .get(`${nodeApiUrl}/events/${slug}`)
        .then((res) => {
          const event = res.data;
          commit("setCurrentEvent", event);
          resolve(event);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        })
        .finally(() => {
          commit("setBoolean", {
            prop: "loadingEvent",
            value: false,
          });
        });
    });
  },
  updateEvent({}, { event_id, ...values }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${nodeApiUrl}/events/${event_id}`, { ...values })
        .then((res) => {
          // SOCKET_UPDATEEVENT takes care of updating the store for all clients
          resolve(res);
        })
        .catch((err) => {
          errorLog(err);
          reject(err);
        });
    });
  },
};

export default eventActions;
