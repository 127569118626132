import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import TheRoom from "../views/TheRoom.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    beforeEnter() {
      window.location.href = "https://hello.atypiclabs.com/gatherings";
    },
  },
  {
    path: "/event/",
    name: "HomeError",
    component: Home,
  },
  {
    path: "/event/:event",
    name: "Home",
    component: Home,
  },
  {
    path: "/experience",
    name: "TheRoom",
    component: TheRoom,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
