var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex RoomNavigationUser overflow-auto theme-user-text",class:{
    '--video': _vm.user.status == 2,
    '--jitsi': _vm.user.status == 1,
    '--current': _vm.current,
  }},[(_vm.user.status == 3)?_c('i',{staticClass:"fas fa-key",staticStyle:{"color":"yellow"}}):(_vm.user.status == 2)?_c('i',{staticClass:"fas fa-video",staticStyle:{"font-size":"0.8rem"}}):_vm._e(),(
      _vm.$store.state.currentUser.admin &&
      _vm.user.user_current_event != _vm.$store.state.currentUser.user_current_event
    )?_c('i',{staticClass:"far fa-user"}):(_vm.user.admin == 1)?_c('i',{staticClass:"fas fa-user-check"}):(_vm.user.admin == 2)?_c('i',{staticClass:"fas fa-user-plus"}):(_vm.user.admin == 3)?_c('i',{staticClass:"fas fa-user-cog"}):_c('i',{staticClass:"fas fa-user"}),_vm._v(" "+_vm._s(_vm.user.user_name)+" "),_c('small',{staticClass:"pl-2"},_vm._l((_vm.numberOfKeys),function(n){return _c('i',{key:n,staticClass:"fas fa-key p-0"})}),0),(_vm.user.user_text)?[_c('small',{staticClass:"p-0 ml-2 text-right"},[_vm._v(" "+_vm._s(_vm.user.user_text)+" ")])]:_vm._e(),(_vm.$store.state.currentUser.admin)?_c('button',{staticClass:"btn btn-link p-0 ml-2",on:{"click":function($event){return _vm.openSettings(_vm.user)}}},[_c('i',{staticClass:"fas fa-cog"})]):_vm._e(),_c('div',{attrs:{"title":_vm.isActive
        ? _vm.$t('message.invite_tooltip', { person: _vm.user.user_name })
        : _vm.$t('message.invite_wait')}},[(
        _vm.$store.state.currentSpace.is_inviting_enabled === 1 &&
        _vm.user.user_current_room != _vm.$store.state.currentUser.user_current_room
      )?_c('button',{staticClass:"btn btn-link p-0 ml-2",attrs:{"disabled":!_vm.isActive && !_vm.$store.state.currentUser.admin},on:{"click":function($event){return _vm.inviteUser(_vm.user, _vm.$store.state.currentUser)}}},[_c('i',{staticClass:"fas fa-envelope"})]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }