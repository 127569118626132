<template>
  <div class="RoomMainContent d-flex align-items-stretch">
    <VueHeadful :title="documentTitle" />
    <BRow style="width: 100%" no-gutters>
      <div style="display: none" v-if="current_experience_mode == 'mill'">
        <!-- LEFT PANEL Cabaret when in division mode -->
        <Jitsi style="flex-grow: 1" />
      </div>

      <BCol v-if="showLeftPannel" cols="4" class="d-flex align-items-stretch">
        <!-- LEFT PANEL Cabaret when in division mode -->
        <Jitsi
          v-if="leftJitsi && currentSpace.alt_videoconf != 'whereby'"
          style="flex-grow: 1"
        />

        <div
          v-if="leftJitsi && currentSpace.alt_videoconf == 'whereby'"
          class="embed-responsive embed-responsive-16by9"
        >
          <iframe
            :src="alt_videconf_url"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            allow="autoplay; fullscreen; camera *; microphone *; display-capture *"
            >Loading…</iframe
          >
        </div>

        <div
          v-else-if="leftWebsite"
          class="embed-responsive embed-responsive-16by9"
        >
          <iframe
            :src="current_content_source"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            allowfullscreen="true"
            allow="autoplay; fullscreen; camera *; microphone *; display-capture *"
            >Loading…</iframe
          >
        </div>

        <component v-else-if="leftStage" :is="stage_source" />
      </BCol>

      <BCol
        :cols="showLeftPannel ? '8' : '12'"
        class="d-flex align-items-stretch"
      >
        <template v-if="debug">
          <div class="RoomMainContent_debug">
            <h2>Debug Mode</h2>
            <!-- Show socket connection status -->
            <h3>Connected to the socket: {{ !$socket.disconnected }}</h3>
            <h3>User: {{ currentUser.user_name }}</h3>
            {{ currentUser }}<br /><br />
            <h3>Room: {{ currentRoom.room_name }}</h3>
            {{ currentRoom }}<br /><br />
            <h3>Space: {{ currentSpace.space_name }}</h3>
            {{ currentSpace }}<br /><br />
            <h3>Event: {{ currentEvent.event_name }}</h3>
            {{ currentEvent }}<br /><br />
            <h3>Users List (attendance: {{ eventAttendance }})</h3>
            {{usersList}}<br /><br />
            <button @click="exitDebug">Exit debug mode</button>
          </div>
        </template>

        <template v-else>
          <!-- RIGHT PANEL, OR FULLSCREEN when no division-->
          <Jitsi
            v-if="rightJitsi && currentSpace.alt_videoconf != 'whereby'"
            class="embed-responsive embed-responsive-16by9"
          />
          <!--TODO this has to be moved into a component -->
          <div
            v-if="rightJitsi && currentSpace.alt_videoconf == 'whereby'"
            class="embed-responsive embed-responsive-16by9"
          >
            <iframe
              :src="alt_videconf_url"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              allow="autoplay; fullscreen; camera *; microphone *; display-capture *"
              >Loading…</iframe
            >
          </div>
          <Whereby
            v-else-if="rightWhereby"
            class="embed-responsive embed-responsive-16by9"
          />
          <img
            v-else-if="rightImage"
            :src="$store.state.imgdir + '/' + currentRoom.page"
          />

          <!-- else, means there's content or cabaret (image or webpage-component -->

          <div
            v-else-if="rightWebsite"
            class="embed-responsive embed-responsive-16by9"
          >
            <!-- special treatment for pdf files, for improved compatibility -->
            <object
              v-if="
                current_content_source.substring(
                  current_content_source.length - 4
                ) == '.pdf'
              "
              :data="current_content_source"
              type="application/pdf"
            >
              <embed :src="current_content_source" type="application/pdf" />
            </object>

            <!-- else, normal website iframe embed -->
            <iframe
              v-else
              :src="current_content_source"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              allow="autoplay; fullscreen; camera *; microphone *; display-capture *"
              >Loading…</iframe
            >
          </div>

          <template v-else>
            <component
              v-if="rightStage"
              :is="stage_source"
              style="align-self: start"
            />

            <!-- load image-content -->
            <img
              v-else-if="currentRoom.page"
              :src="$store.state.imgdir + '/' + currentRoom.page"
            />
          </template>
        </template>
      </BCol>
    </BRow>
  </div>
</template>

<script>
/* global JitsiMeetExternalAPI */
import { mapState } from "vuex";
import { BRow, BCol } from "bootstrap-vue";
import ImageFullsize from "@/components/ImageFullsize.vue";
import WebpageWebcastTwitch from "@/components/webpage/WebpageWebcastTwitch.vue";
import WebpageWebcastTwitch2 from "@/components/webpage/WebpageWebcastTwitch2.vue";
import WebpageWebcastWeb from "@/components/webpage/WebpageWebcastWeb.vue";
import WebpageAgoraStage from "@/components/webpage/WebpageAgoraStage.vue";
import WebpageAgoraStage2 from "@/components/webpage/WebpageAgoraStage2.vue";
import WebpageWebcastVimeo from "@/components/webpage/WebpageWebcastVimeo.vue";
import WebpageWebcastYoutube from "@/components/webpage/WebpageWebcastYoutube.vue";
import WebpageWebcastYoutubeTest from "@/components/webpage/WebpageWebcastYoutubeTest.vue";
import WebpageLivreDor from "@/components/webpage/WebpageLivreDor.vue";
import WebpageEOGuestBook from "@/components/webpage/WebpageEOGuestBook.vue";
import Jitsi from "@/components/Jitsi/Jitsi.vue";
import Whereby from "@/components/Videoconf/Whereby.vue";
import WherebyAlt from "@/components/Videoconf/WherebyAlt.vue";
import VueHeadful from "vue-headful";

export default {
  components: {
    BCol,
    BRow,
    ImageFullsize,
    WebpageWebcastTwitch,
    WebpageWebcastTwitch2,
    WebpageWebcastWeb,
    WebpageAgoraStage,
    WebpageAgoraStage2,
    WebpageWebcastVimeo,
    WebpageWebcastYoutube,
    WebpageWebcastYoutubeTest,
    WebpageLivreDor,
    WebpageEOGuestBook,
    Jitsi,
    Whereby,
    WherebyAlt,
    VueHeadful,
  },
  props: {},
  mounted() {},
  computed: {
    ...mapState([
      "currentUser",
      "currentRoom",
      "currentSpace",
      "currentEvent",
      "debug",
      "usersList",
      "eventAttendance",
    ]),
    documentTitle() {
      const room_name = this.currentRoom.room_name;
      const space_name = this.currentSpace.space_name;
      const event_name = this.currentEvent.event_name;
      const user_name = this.currentUser.user_name;
      return `
        Agora - ${room_name} - ${space_name} @ ${event_name} [${user_name}]
      `;
    },
    user_name_encoded() {
      return encodeURIComponent(this.currentUser.user_name);
    },
    tagline_encoded() {
      return encodeURIComponent(this.currentUser.user_text);
    },
    user_name_and_tagline_encoded() {
      var compound_name = this.currentUser.user_name;
      if (this.currentUser.user_text !== null)
        compound_name =
          this.currentUser.user_name + " - " + this.currentUser.user_text;
      return encodeURIComponent(compound_name);
    },
    current_experience_mode() {
      if (this.currentRoom.experience_mode) {
        return this.currentRoom.experience_mode;
      } else {
        return this.currentSpace.d_experience_mode;
      }
    },
    alt_videconf_url() {
      var result_url = "";
      var modifiers_for_all = "&background=off&leaveButton=off";
      var base_url = this.currentRoom.alt_videoconf_source;
      if (this.currentUser.admin != 0) {
        base_url = this.currentRoom.alt_videoconf_source2;
      }
      // Pass user name and eventlanguage
      result_url =
        base_url +
        "&" +
        "lang=" +
        this.currentEvent.lan +
        "&" +
        "displayName=" +
        this.user_name_and_tagline_encoded;
      // Set room to muted if requested by the mode
      if (
        this.current_experience_mode == "socialmuted_stage" ||
        this.current_experience_mode == "stage_socialmuted" ||
        this.current_experience_mode == "socialmuted"
      ) {
        result_url = result_url + "&audio=off";
      }
      // Pass settings applicable to all
      result_url = result_url + modifiers_for_all;

      return result_url;
    },
    current_content_source() {
      var content_source_output = ""; //add some default website here
      if (this.currentRoom.content_source) {
        content_source_output = this.currentRoom.content_source;
      } else {
        content_source_output = this.currentSpace.d_content_source;
      }
      //SPECIAL URL CASES:

      //WHEREBY SERVICE - Add username through switch
      if (content_source_output.includes("whereby.com")) {
        content_source_output =
          content_source_output +
          "&" +
          "lang=" +
          this.currentEvent.lan +
          "&" +
          "displayName=" +
          this.user_name_and_tagline_encoded;
      }

      //SLIDO SERVICE - Add username through switch
      if (content_source_output.includes("sli.do")) {
        content_source_output =
          content_source_output +
          "?" +
          "lang=" +
          this.currentEvent.lan +
          "&" +
          "user_name=" +
          this.user_name_and_tagline_encoded;
      }

      return content_source_output;
    },
    leftStage() {
      // transitioning names to new standard. Old names still valid
      // options: social, socialmuted, stage, website, image
      // he partitions are: left_right (ex: stage_social). If only one name, use all the space (right partition)
      return (
        this.current_experience_mode == "stage_social" ||
        this.current_experience_mode == "announcement"
      );
    },
    leftJitsi() {
      return (
        this.current_experience_mode == "social_image" ||
        this.current_experience_mode == "cabaret1" ||
        this.current_experience_mode == "cabaret2" ||
        this.current_experience_mode == "social_stage" ||
        this.current_experience_mode == "cabaret2muted" ||
        this.current_experience_mode == "socialmuted_stage" ||
        this.current_experience_mode == "social_website" ||
        this.current_experience_mode == "cabaret_website"
      );
    },
    leftWebsite() {
      return (
        this.current_experience_mode == "website_stage" ||
        this.current_experience_mode == "website_social"
      );
    },
    rightImage() {
      return (
        this.current_experience_mode == "social_image" ||
        this.current_experience_mode == "image_only" ||
        this.current_experience_mode == "image"
      );
    },
    rightStage() {
      return (
        this.current_experience_mode == "cabaret1" ||
        this.current_experience_mode == "cabaret2" ||
        this.current_experience_mode == "social_stage" ||
        this.current_experience_mode == "cabaret2muted" ||
        this.current_experience_mode == "socialmuted_stage" ||
        this.current_experience_mode == "mill" ||
        this.current_experience_mode == "stage_only" ||
        this.current_experience_mode == "stage" ||
        this.current_experience_mode == "website_stage"
      );
    },
    rightWebsite() {
      return (
        this.current_experience_mode == "website" ||
        this.current_experience_mode == "stage_website" ||
        this.current_experience_mode == "cabaret_website"
      );
    },
    rightJitsi() {
      return (
        this.current_experience_mode == "" ||
        this.current_experience_mode == "room_only" ||
        this.current_experience_mode == "room" ||
        this.current_experience_mode == "social" ||
        this.current_experience_mode == "room_only_muted" ||
        this.current_experience_mode == "socialmuted" ||
        this.current_experience_mode == "room_muted" ||
        this.current_experience_mode == "website_social" ||
        this.leftStage
      );
    },
    rightWhereby() {
      return this.current_experience_mode == "wherebyroom";
    },
    showLeftPannel() {
      return this.leftJitsi || this.leftStage || this.leftWebsite;
    },
    stage_source() {
      if (this.currentRoom.stage_source) {
        return this.currentRoom.stage_source;
      } else {
        return this.currentSpace.stage_source;
      }
    },
    status() {
      if (this.debug) {
        return 0;
      }
      if (this.rightJitsi || this.leftJitsi) {
        return 1;
      }
      if (!this.currentRoom.page && !this.currentRoom.webpage) {
        return 0;
      }
      return 0;
    },
  },
  mounted() {
    this.$store.dispatch("setCurrentUserStatus", this.status);
  },
  watch: {
    status() {
      this.$store.dispatch("setCurrentUserStatus", this.status);
    },
  },
  methods: {
    exitDebug() {
      this.$router.replace({ query: { debug: undefined } });
      this.$store.commit("setDebug", 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.RoomMainContent {
  max-height: calc(100vh - 70px);
  overflow-y: scroll;

  flex-grow: 1;
  &_debug {
    background-color: #fffa;
    color: black;
  }
  img {
    object-fit: contain;
    width: 100%;
    align-self: start;
  }
}
</style>
