<template>
  <article>
    <h3>Bienvenue</h3>
    <br />
    <p>
      Nous sommes heureux de vous retrouver ici! Ceci est un espace spécialement
      conçu pour échanger et connecter avec notre communauté.
    </p>
    <p>
      Nous vous invitons à être actifs et ouverts aux connexions tout au long de
      l'événement, lors du co-visionnement, discussions et les moments de
      réseautage libre.
    </p>
    <p>Quelques consignes pour améliorer votre expérience:</p>
    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Il est fortement conseillé d’utiliser un
        ordinateur pour profiter au maximum de l’expérience. Les
        <strong>iPhones et iPads sont déconseillés, </strong> car certaines
        versions peuvent poser des problèmes avec le partage du microphone et de
        la caméra.
      </li>
      <li>
        <i class="fa fa-chrome"></i> L’utilisation du navigateur Google Chrome
        est recommandée. Vous pouvez cliquer
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          ici
        </a>
        pour l’installer, si vous ne l’avez pas.
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>Il faut autoriser la
        connexion vidéo et audio lorsque demandé.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Il est possible que
        l’audio devienne muet lorsque tu changes de salles ou de tables, il
        suffit simplement de le réactiver.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Nous vous invitons à éteindre votre
        téléphone pour profiter pleinement du moment que vous allez passer avec
        nous.
      </li>
    </ul>
    <br />
    <p>Bon événement !</p>
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
