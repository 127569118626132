<template>
  <div class="Home">
    <VueHeadful
      v-if="currentEvent.event_name"
      :title="'Agora by Atypic Labs - ' + currentEvent.event_name"
    />
    <div class="hero">
      <img
        :key="currentEvent.event_id"
        v-if="fields.header"
        class="Home_banner"
        :src="`${imgdir}/img/graphics/${fields.header}`"
      />
    </div>

    <article class="container">
      <BSpinner v-if="loadingEvent" />
      <div v-else class="row">
        <div class="col-md">
          <component v-if="fields.greeting" :is="fields.greeting" />
        </div>
        <div class="col-md">
          <HomeRight :fields="fields" :event="currentEvent" :user="user" />
        </div>
      </div>
    </article>
    <img
      v-if="fields.footer"
      :key="currentEvent.event_id"
      class="Home_banner"
      :src="`${imgdir}/img/graphics/${fields.footer}`"
    />
  </div>
</template>

<script>
import GreetingComposite from "@/components/Greetings/GreetingComposite.vue";
import GreetingGeneric from "@/components/Greetings/GreetingGeneric.vue";
import GreetingFromDatabase from "@/components/Greetings/GreetingFromDatabase.vue";
import GreetingMill from "@/components/Greetings/GreetingMill.vue";
import GreetingAp from "@/components/Greetings/GreetingAp.vue";
import GreetingApSecret from "@/components/Greetings/GreetingApSecret.vue";
import GreetingLovary from "@/components/Greetings/GreetingLovary.vue";
import GreetingEoCalgary from "@/components/Greetings/GreetingEoCalgary.vue";
import GreetingEoMontreal from "@/components/Greetings/GreetingEoMontreal.vue";
import GreetingVbga from "@/components/Greetings/GreetingVbga.vue";
import GreetingEoMontrealAi from "@/components/Greetings/GreetingEoMontrealAi.vue";
import GreetingAdopte from "@/components/Greetings/GreetingAdopte.vue";
import GreetingComposite26 from "@/components/Greetings/GreetingComposite26.vue";
import GreetingCaprice from "@/components/Greetings/GreetingCaprice.vue";
import GreetingCaprice2 from "@/components/Greetings/GreetingCaprice2.vue";
import GreetingClassics from "@/components/Greetings/GreetingClassics.vue";
import GreetingYpoCalgary from "@/components/Greetings/GreetingYpoCalgary.vue";
import GreetingForumNumix from "@/components/Greetings/GreetingForumNumix.vue";
import GreetingGenericFR from "@/components/Greetings/GreetingGenericFR.vue";
import GreetingDgqmFr from "@/components/Greetings/GreetingDgqmFr.vue";
import GreetingDgqmDe from "@/components/Greetings/GreetingDgqmDe.vue";
import GreetingDqla from "@/components/Greetings/GreetingDqla.vue";
import GreetingCM from "@/components/Greetings/GreetingCM.vue";
import GreetingCMCop26fr from "@/components/Greetings/GreetingCMCop26fr.vue";
import GreetingCMCop26en from "@/components/Greetings/GreetingCMCop26en.vue";
import GreetingPdc from "@/components/Greetings/GreetingPdc.vue";
import GreetingNouvLR from "@/components/Greetings/GreetingNouvLR.vue";
import GreetingCommunity from "@/components/Greetings/GreetingCommunity.vue";
import GreetingIndieCampus from "@/components/Greetings/GreetingIndieCampus.vue";
import GreetingMAPP from "@/components/Greetings/GreetingMAPP.vue";
import GreetingAgir from "@/components/Greetings/GreetingAgir.vue";

import HomeRight from "@/components/Home/HomeRight.vue";
import { BSpinner } from "bootstrap-vue";
import VueHeadful from "vue-headful";
import { mapState } from "vuex";
import { getUserID } from "@/helper/helper.js";

export default {
  name: "Home",
  components: {
    GreetingComposite,
    GreetingGeneric,
    GreetingFromDatabase,
    GreetingMill,
    GreetingAp,
    GreetingApSecret,
    GreetingLovary,
    GreetingEoCalgary,
    GreetingEoMontreal,
    GreetingVbga,
    GreetingEoMontrealAi,
    GreetingAdopte,
    GreetingComposite26,
    GreetingCaprice,
    GreetingCaprice2,
    GreetingClassics,
    GreetingYpoCalgary,
    GreetingForumNumix,
    GreetingGenericFR,
    GreetingDgqmFr,
    GreetingDgqmDe,
    GreetingDqla,
    GreetingCM,
    GreetingCMCop26fr,
    GreetingCMCop26en,
    GreetingPdc,
    GreetingNouvLR,
    GreetingCommunity,
    GreetingIndieCampus,
    GreetingMAPP,
    GreetingAgir,

    BSpinner,
    HomeRight,
    VueHeadful,
  },
  beforeMount() {
    // need to get the current event from the slug
    // or the user or the event if they are in the store
    const eventSlug = this.$route.params.event;
    if (!eventSlug) {
      alert("Error: no event");
      return;
    }

    if (eventSlug != this.currentEvent.event_id_text) {
      this.$store.dispatch("loadCurrentEventBySlug", { slug: eventSlug });
    }

    // check if we can find a user ID
    // If so, load the user
    const userID = getUserID(this.$store.state);
    if (userID) {
      // get the user, but don't commit it to the store because we are not logged in.
      this.$store
        .dispatch("loadUserById", { user_id: userID })
        .then((user) => {
          this.user = user;
        })
        .catch((err) => {
          console.error(err);
        });
      // we are at the login page, so remove the current user
      // this will also log them out of the socket
      // (socket will stay connected, with no user attached)
      this.$store.commit("setCurrentUser", {});
    }
  },
  data() {
    const result = {
      user: {},
      default_fields: {
        user_name_label: "Your name",
        user_name_placeholder: "Enter your name here",
        user_metadata: "",
        user_metadata_label: "",
        user_metadata_label_tip: "",
        submit_value: "🌟  Enter the Experience",
        card_header_now: "Enter the experience",
        card_header_soon: "See you soon",
        card_header_finished: "Thank you",
        card_header_next: "Thank you",
        start_time_soon: "",
        soon_text: "Looking forward to it!",
        greeting: "",
        header: "",
        footer: "",
      },
    };
    return result;
  },
  computed: {
    ...mapState(["currentEvent", "imgdir", "loadingEvent"]),
    fields() {
      if (this.currentEvent?.event_page_fields) {
        return {
          ...this.default_fields,
          ...JSON.parse(this.currentEvent.event_page_fields),
        };
      }
      return this.default_fields;
    },
  },
};
</script>

<style lang="scss" scoped>
.Home {
  &_banner {
    width: 100%;
  }
}

.hero {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  h1 {
    position: absolute;
    top: 64px;
    left: 40px;
    max-width: 512px;
    font-weight: bold;
    color: white;
    line-height: 140%;

    span {
      background-color: black;
      filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.1));
    }
  }
}

.col-md {
  margin-top: 40px;
  padding: 0 2.5% 0 2.5%;
}

.card {
  border: solid 1px lightestgray;
  border-radius: 5px;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.05));
  margin-bottom: 24px;
  padding-bottom: 2px;
}

.submit {
  padding-top: 8px;
  margin-bottom: 8px;
}

.card-body {
  background-color: white;
}

.mood-field {
  margin: 10px 0 0;
}

.label-tip {
  font-size: 0.8em;
  color: gray;
  display: block;
}
</style>
