<template>
  <article>
    <h3>
      Welcome to the Exclusive Only in EO Virtual Dine Around in the comfort of
      your home this year!
    </h3>
    <br />
    <strong>For Best Experience, may we suggest:</strong>
    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Get your computers ready to join (iPhone
        and iPad NOT supported)
      </li>
      <li>
        <i class="fa fa-chrome"></i> Please join from your Chrome browser.
        Here’s a
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en"
          >link to install</a
        >
        in case you don’t use Chrome.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Your browser may
        prompt you to give video and audio permissions. Simply click allow.
      </li>
      <li>
        <i class="fas fa-utensils"></i>Enjoy your 4 course meal brought to you
        by member Jayme McFaden of UNA Pizza and Wine and Bread & Circus as you
        move to your next restaurant, advised by your table captain.
      </li>
      <li>
        <i class="fas fa-wine-glass-alt"></i>Turn your phones off and enjoy this
        Only in EO Virtual Dine Around experience.
      </li>
    </ul>
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
