<template>
  <div
    class="iframe-container"
    style="overflow: hidden; padding-top: 0%; position: relative"
  >
    <iframe
      src="https://player.twitch.tv/?channel=jococo1221&parent=agora.atypiclabs.com&muted=true"
      height="360"
      width="1280"
      frameborder="0"
      scrolling="no"
      allowfullscreen="true"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped></style>
