<template>
  <article>
    <p>
      Bienvenue! Dans cette prochaine phase de l'événement, nous sommes ravis de
      vous accueillir dans notre villa virtu’ose! C'est un espace créé
      spécialement pour vous! Pour se réunir, discuter, s’inspirer et co-créer!
      La magie de la création est entre nos mains.

      <br />
    </p>

    <h1>Pour une meilleure expérience:</h1>
    <p></p>
    <ul>
      <li>
        Veuillez utiliser votre ordinateur et vous connecter idéalement à un
        navigateur Chrome
      </li>
      <li>
        Une fois à l'intérieur, votre navigateur vous invitera à donner des
        autorisations vidéo et audio. Cliquez sur "autoriser".
      </li>
      <li>
        Depuis la salle d'accueil, explorez les différents coins de notre manoir
      </li>
      <li>
        Nous vous recommandons de garder votre vidéo dans Toggle Tile View (vue
        galerie)
      </li>
      <li>
        Éteignez vos téléphones, prenez votre boisson préférée et
        mélangeons-nous!
      </li>
    </ul>

    <h1>Les règles d’Or:</h1>
    <ul>
      <li>Je me respecte ainsi que le monde autour de moi.</li>
      <li>Je parle de mon expérience.</li>
      <li>J’honore la présence de chaque personne.</li>
      <li>Je n'interrompt pas la personne qui parle.</li>
      <li>Je suis libre de changer d’espace quand bon me semble.</li>
      <li>Chacun/e est responsable de son expérience.</li>
    </ul>
    <p>
      En entrant dans l'expérience, j'accepte de respecter les règles d’Or
      ci-dessus du manoir en ligne de Mme L'Ovary.
    </p>
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped></style>
