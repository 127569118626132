<template>
  <BModal
    :title="`Administrator Menu @ ${room && room.room_name}`"
    ok-only
    ref="roomAdminModal"
    @hidden="room = {}"
  >
    <div v-if="currentEvent.event_id_text == 'ap'">
      <ul>
        <li v-for="user in otherUsers" :key="user.user_id">
          {{ user.user_name }}
        </li>
      </ul>
      <div>
        <button @click="sendToSpace(2122)">Send all to Junge ruins</button>
      </div>
      <!-- <div>
          <button @click="sendToSpace(2132)">Send all to Astral Plane</button>
        </div> -->
    </div>
    <h3>Event: {{ currentEvent.event_name }}</h3>
    <p>Status: {{ currentEvent.event_status }}</p>
    <div>
      <BFormGroup label="" v-slot="{ ariaDescribedby }">
        <BFormRadioGroup
          @input="updateEventStatus"
          id="target_room_radio"
          v-model="eventStatusSelected"
          :options="options_event_status"
          :aria-describedby="ariaDescribedby"
          name="radios-btn-default"
          buttons
        ></BFormRadioGroup>
      </BFormGroup>
    </div>

    <hr />

    <h3>Space: {{ currentSpace.space_name }}</h3>
    <p>Space Mode: {{ currentSpace.d_experience_mode }}</p>

    <div>
      <BFormGroup label="" v-slot="{ ariaDescribedby }">
        <BFormRadioGroup
          @input="updateSpaceMode"
          id="space_mode_radio"
          v-model="spaceModeSelected"
          :options="options_space_mode"
          :aria-describedby="ariaDescribedby"
          name="radios-btn-default2"
          buttons
          stacked
        ></BFormRadioGroup>
        <BFormRadioGroup
          @input="updateSpaceMode"
          id="space_mode_radio"
          v-model="spaceModeSelected"
          :options="options_space_mode2"
          :aria-describedby="ariaDescribedby"
          name="radios-btn-default2"
          buttons
          stacked
        ></BFormRadioGroup>
        <BFormRadioGroup
          @input="updateSpaceMode"
          id="space_mode_radio"
          v-model="spaceModeSelected"
          :options="options_space_mode3"
          :aria-describedby="ariaDescribedby"
          name="radios-btn-default2"
          buttons
          stacked
        ></BFormRadioGroup>
      </BFormGroup>
    </div>
    <div>
      <BFormGroup title="This applies to all users.">
        <p>Show or Hide Menu for all users</p>
        <BButton @click="collapseNavForAll"> Collapse Nav for All </BButton>
        <BButton @click="expandNavForAll"> Expand Nav for All </BButton>
      </BFormGroup>
    </div>

    <BForm @submit="submitAnnouncement">
      <BFormGroup class="BFormGroup" label="Message:" label-for="announcement">
        <div class="d-flex flex-row">
          <BFormInput
            id="announcement"
            autocomplete="off"
            v-model="formInfo.announcement"
            placeholder="Enter your message"
            class="FormTextArea"
          />
          <BButton type="submit" variant="dark">Ok</BButton>
        </div>
      </BFormGroup>
    </BForm>
  </BModal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormRadioGroup,
  BForm,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormRadioGroup,
    BForm,
    BFormInput,
    BButton,
  },
  data() {
    return {
      room: {},
      eventStatusSelected: "",
      spaceModeSelected: "",
      formInfo: {
        announcement: "",
      },
      formInfo2: {
        target_room_text2: "",
      },
      success: {
        target_room_text2: false,
      },
      options_event_status: [
        { text: "Upcoming event", value: "soon" },
        { text: "Ongoing now", value: "now" },
        { text: "Finished event", value: "finished" },
      ],
      options_space_mode: [
        { text: "Stage", value: "stage" },
        { text: "Stage + Social", value: "stage_social" },
        { text: "Stage + Website", value: "stage_website" },
        { text: "Website + Stage", value: "website_stage" },
      ],
      options_space_mode2: [
        { text: "Social ", value: "social" },
        { text: "Social + Stage", value: "social_stage" },
        { text: "SocialM", value: "socialmuted" },
        { text: "SocialM + Stage", value: "socialmuted_stage" },
        { text: "Social + Website", value: "social_website" },
      ],
      options_space_mode3: [
        { text: "Image", value: "image" },
        { text: "Social + Image", value: "social_image" },
        { text: "Website", value: "website" },
      ],
      options_menu_collapse_mode: [
        { text: "Show Menu", value: 0 },
        { text: "Hide Menu", value: 1 },
      ],
    };
  },
  computed: {
    ...mapState([
      "currentUser",
      "currentEvent",
      "currentSpace",
      "usersList",
      "eventAttendance",
    ]),
    usersInRoom() {
      return this.usersList.filter(
        (u) =>
          u.user_current_room == this.room.room_id &&
          this.eventAttendance.includes(u.user_id)
      );
    },
    otherUsers() {
      if (!this.usersInRoom?.length) {
        return [];
      }
      return this.usersInRoom.filter((u) => {
        return u.user_id !== this.currentUser.user_id;
      });
    },
  },
  mounted() {
    // Align space_mode with store value on mount
    this.spaceModeSelected = this.currentSpace.d_experience_mode;
    this.eventStatusSelected = this.currentEvent.event_status;
  },
  watch: {
    // Keep this selections aligned with store values upon changes
    "currentEvent.event_status"() {
      this.eventStatusSelected = this.currentEvent.event_status;
    },
    "currentSpace.d_experience_mode"() {
      this.spaceModeSelected = this.currentSpace.d_experience_mode;
    },
    "currentSpace.is_menu_collapsed_globally"() {
      this.navCollapseModeSelected =
        this.currentSpace.is_menu_collapsed_globally;
    },
  },
  methods: {
    // Submit Announcement
    submitAnnouncement(e) {
      e.preventDefault();
      const data = {
        space_id: this.currentSpace.space_id,
        tmp_message: this.formInfo.announcement,
      };
      // Emit to server to trigger broadcast
      this.$socket.client.emit("announcementfromclient", data);

      // Clear form
      this.formInfo.announcement = "";
    },
    // Update Menu Collapse Mode for all users in this space
    expandNavForAll() {
      this.$socket.client.emit("expandNavForSpace", this.currentSpace.space_id);
    },
    collapseNavForAll() {
      this.$socket.client.emit(
        "collapseNavForSpace",
        this.currentSpace.space_id
      );
    },
    // Update Space Mode for all users in this space
    updateSpaceMode() {
      const data = {
        space_id: this.currentSpace.space_id,
        d_experience_mode: this.spaceModeSelected,
      };

      this.$store.dispatch("updateSpace", data);
    },

    // Update Event Status for all users
    updateEventStatus() {
      const data = {
        event_id: this.currentEvent.event_id,
        event_status: this.eventStatusSelected,
      };

      this.$store.dispatch("updateEvent", data);
    },
    show(room) {
      if (!room) {
        console.warn("No room given");
        return;
      }
      this.room = room;
      this.$refs.roomAdminModal.show();
    },
    sendToSpace(space_id) {
      this.$store
        .dispatch("giveAccessAndSendGroupUsersToSpace", {
          space_id,
          users: this.otherUsers,
        })
        .catch()
        .then(this.$refs.roomAdminModal.hide());
    },

    showSuccess(field) {
      this.success[field] = true;
      if (this.formInfo[field]) {
        this.formInfo[field] = "";
      }
      this.interval = setInterval(() => {
        this.success[field] = false;
      }, 10000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
