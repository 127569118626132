<template>
  <article>
    <h3>Forum virtuel dans le cadre de la COP26</h3>
    <br />
    <p>
      Ce forum met en lumière la contribution de la culture à la transition
      écologique et mobilise les acteurs culturels et environnementaux autour de
      cet enjeu majeur.
    </p>
    <p>
      De quelle façon la culture peut-elle être partie prenante de ce grand
      mouvement de responsabilisation ?
    </p>
    <br />
    <p>Quelques consignes pour améliorer votre expérience:</p>
    <ul class="icon-list">
      <li>
        <i class="fas fa-desktop"></i> Il est fortement conseillé d’utiliser un
        ordinateur pour profiter au maximum de l’expérience. Les
        <strong>iPhone et iPad sont déconseillés, </strong> car certaines
        versions peuvent poser des problèmes avec le partage du microphone et de
        la caméra.
      </li>
      <li>
        <i class="fa fa-chrome"></i> L’utilisation du navigateur Google Chrome
        est recommandée. Vous pouvez cliquer
        <a
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          ici
        </a>
        pour l’installer.
      </li>
      <li>
        <i class="fas fa-video" aria-hidden="true"></i>Si demandé, autorisez la
        connexion VIDÉO et AUDIO.
      </li>
      <li>
        <i class="fas fa-headphones" aria-hidden="true"></i>Votre microphone
        pourrait automatiquement devenir muet lorsque vous changez de
        destination, il suffit simplement de le réactiver.
      </li>
      <li>
        <i class="fas fa-phone-slash"></i>Nous vous invitons à éteindre votre
        téléphone afin de profiter pleinement du moment.
      </li>
    </ul>
    <br />
    <p>Bon événement !</p>
    <br />
    <br />
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin-top: 16px;

  li {
    padding: 8px 0;
    vertical-align: center;
  }
  i {
    padding-right: 8px;
  }
}

.footer {
  height: 36px;
  object-fit: cover;
}
</style>
