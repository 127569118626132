<template>
  <article>
    <h1>Welcome!</h1>
    <p>
      You are at the social experience of Composite #24, at Agora - by Atypic
      Labs.
    </p>
    <p>
      This experience is created to allow for spontaneous encounters and by
      facilitating connections in a self-guided environment. You can connect
      with fellow attendees, or the artists and presenters of Composite in their
      respective rooms.
    </p>
    <p>
      You are part of the nascent stage of this kind of online social
      interaction. Be patient, explore the platform, and feel free to leave us
      any sort of constructive feedback!
    </p>

    <h2>May we suggest:</h2>
    <p></p>
    <ul>
      <li>
        From the Welcome room, explore the different spaces. Some rooms include
        a description with suggested ways to connect with others inside that
        space; follow these prompts or invent your own ways to share this
        experience with others.
      </li>
      <li>
        For best experience - please use your computer.
        <b>Safari browser won't work </b> (for now)
      </li>
      <li>
        Your browser will prompt you to give video and audio permissions. Simply
        click allow.
      </li>
      <li>Keep your video on in Toggle Tile View (gallery view).</li>
      <li>
        Turn off your phones, grab your favorite drink, and let's mix and
        mingle!
      </li>
    </ul>
    <p>See you in a bit!</p>
  </article>
</template>

<script>
export default {
  props: {},
};
</script>

<style lang="scss" scoped></style>
