<template>
  <div class="card">
    <h4 class="card-header">
      {{ cardHeader }}
    </h4>
    <BTabs card>
      <BTab :title="$t('message.login_normal')">
        <div v-if="isios" class="text-danger">
          <b>
            <p>
              {{ $t("message.login_compatibility") }}
            </p>
          </b>
        </div>
        <div v-if="currentEvent.event_status == 'no_event'">
          <h3>This event doesn't exist</h3>
          <p>Please contact the event organizer</p>
        </div>
        <div v-if="currentEvent.event_status == 'finished'">
          <br />
          <h3 v-if="fields.finished_text">
            {{ fields.finished_text }}
          </h3>
          <h3 v-else>
            It was great connecting with you! Please stay tuned for our next
            event.
          </h3>
          <br />
        </div>
        <div v-if="currentEvent.event_status == 'next'">
          <h3>Looking forward to seeing you soon again!</h3>
          <br />
          <p v-if="currentEvent.start_time">
            The next event is scheduled for
            {{ currentEvent.start_time | moment }} ET.
          </p>
        </div>
        <div v-if="currentEvent.event_status == 'soon'">
          <div v-if="currentEvent.start_time">
            <p v-if="fields.start_time_soon">
              {{ fields.start_time_soon }}
            </p>
            <p v-else>Event starts {{ currentEvent.start_time | moment }} ET</p>
          </div>
          <p>
            {{ fields.soon_text }}
          </p>
        </div>
        <div
          v-if="
            currentEvent.event_capacity != 0 &&
            eventAttendance.length >= currentEvent.event_capacity &&
            !returningUser
          "
        >
          <p>
            {{ $t("message.login_full") }}
          </p>
          <p v-if="currentEvent.event_backup">
            {{ $t("message.login_alt") }} {{ currentEvent.event_backup }}
          </p>
        </div>
        <div v-if="currentEvent.event_status == 'issue'">
          <p>
            {{ $t("message.login_issue") }}
          </p>
          <p v-if="currentEvent.event_backup">
            {{ $t("message.login_alt") }} {{ currentEvent.event_backup }}
          </p>
        </div>
        <template v-if="showEntryForm">
          <HomeRightForm ref="HomeRightForm" :fields="fields" :user="user" />

          <div v-if="fields.alt_event" v-html="fields.alt_event" />
        </template>
      </BTab>
      <BTab
        v-if="fields.backstage || this.$route.query.backstage == 'true'"
        :title="$t('message.login_admin')"
      >
        <HomeRightForm
          ref="HomeRightForm-backstage"
          :fields="fields"
          :backstage="true"
          :user="user"
        />
      </BTab>
    </BTabs>
  </div>
</template>

<script>
import HomeRightForm from "@/components/Home/HomeRightForm.vue";
import { BTabs, BTab } from "bootstrap-vue";
import { isIOS, isSafari } from "mobile-device-detect";
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  components: {
    HomeRightForm,
    BTabs,
    BTab,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const result = {
      isios: isIOS,
      issafari: isSafari,
    };
    return result;
  },
  computed: {
    ...mapState(["currentEvent", "eventAttendance"]),
    cardHeader() {
      switch (this.currentEvent.event_status) {
        case "now":
          return this.fields.card_header_now;
        case "soon":
          return this.fields.card_header_soon;
        case "finished":
          return this.fields.card_header_finished;
        case "next":
          return this.fields.card_header_next;
        case "issue":
          return "";
        default:
          return "error";
      }
    },
    returningUser() {
      return !!this.user?.user_id;
    },
    showEntryForm() {
      // if (this.isios) {
      //   return true; //iPhones and iPads might be supported since Jitsi summer upgrade - opening this to test
      // }
      if (
        this.currentEvent.event_status != "now" &&
        this.$route.query.event_status != "now"
      ) {
        return false;
      }
      if (
        this.currentEvent.event_capacity != 0 &&
        this.eventAttendance.length >= this.currentEvent.event_capacity &&
        !this.returningUser //todo - this will allow any user who has ever logged in before to log in, even if event is full
      ) {
        return false;
      }
      return true;
    },
  },
  filters: {
    moment(date) {
      // let timedate = moment(date).utc().tz('America/Montreal').format('dddd, MMMM Do YYYY, h:mm:ss a z');
      let timedate = moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a");
      return timedate;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: solid 1px lightestgray;
  border-radius: 5px;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.05));
  margin-bottom: 24px;
  padding-bottom: 2px;
}
.card-body {
  background-color: white;
}
</style>
