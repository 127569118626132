<template>
  <BRow class="RoomMain_Row" no-gutters>
    <BCol
      class="RoomMain_Col d-flex align-items-stretch"
      cols="12"
      :lg="navigationCollapsed ? 12 : 9"
    >
      <div class="RoomMain_VideoArea d-flex align-items-stretch">
        <RoomMainYoutube
          v-if="showYoutube"
          :key="videoLink"
          ref="RoomMainYoutube"
          :videoLink="videoLink"
          @finished="youtubeFinished"
        />
        <div style="width: 60%; margin: auto" v-else-if="keysEarned">
          <RoomMainKeyClaimForm
            ref="RoomMainKeyClaimForm"
            v-for="earnedKey of keysEarned"
            :key="earnedKey.id"
            :earnedKey="earnedKey"
          />
        </div>
        <div
          ref="roomDetails"
          style="width: 60%; margin: auto"
          v-else-if="details && !detailsAccepted"
        >
          <div class="card" style="color: black">
            <div class="card-body" v-html="details" />
            <div class="card-footer">
              <button ref="roomDetailsOkButton" @click="detailsAccepted = true">
                OK
              </button>
            </div>
          </div>
        </div>
        <div class="RoomMain_Inner d-flex align-items-stretch" v-else>
          <RoomMainContent ref="RoomMainContent" />
        </div>
        <LoadingSpinner
          v-if="loadingJitsi || loadingYoutube"
          class="RoomMain_Loading"
        />
      </div>
    </BCol>
  </BRow>
</template>

<script>
import { mapState } from "vuex";
import { BRow, BCol } from "bootstrap-vue";
import RoomMainContent from "@/components/RoomMain/RoomMainContent.vue";
import RoomMainYoutube from "@/components/RoomMain/RoomMainYoutube.vue";
import RoomMainKeyClaimForm from "@/components/RoomMain/RoomMainKeyClaimForm.vue";
import LoadingSpinner from "@/components/Basic/LoadingSpinner.vue";

export default {
  components: {
    BRow,
    BCol,
    RoomMainContent,
    RoomMainYoutube,
    LoadingSpinner,
    RoomMainKeyClaimForm,
  },
  props: {},
  data() {
    return {
      youtubePlayed: false,
      detailsAccepted: false,
    };
  },
  computed: {
    ...mapState([
      "currentRoom",
      "currentUser",
      "navigationCollapsed",
      "loadingJitsi",
      "loadingYoutube",
      "earnedKeys",
    ]),

    videoLink() {
      return this.currentRoom.video_link;
    },
    details() {
      return this.currentRoom.room_details;
    },

    showYoutube() {
      return !this.youtubePlayed && this.videoLink;
    },

    keysEarned() {
      if (!this.earnedKeys || !this.earnedKeys.length) {
        return null;
      }
      const keys = this.earnedKeys.filter((ek) => {
        return ek.room_id == this.currentRoom.room_id && !ek.claimed;
      });
      if (!keys || !keys.length) {
        return null;
      }
      return keys;
    },
    status() {
      if (this.showYoutube) {
        return 2;
      }
      if (this.keysEarned) {
        return 3;
      }
      return 0;
    },
  },
  mounted() {
    this.youtubePlayed = this.hasVideoBeenPlayed(this.videoLink);
    this.$store.dispatch("setCurrentUserStatus", this.status);
  },
  watch: {
    videoLink(newVal) {
      this.youtubePlayed = this.hasVideoBeenPlayed(newVal);
    },
    details() {
      this.detailsAccepted = false;
    },
    status() {
      this.$store.dispatch("setCurrentUserStatus", this.status);
    },
  },
  methods: {
    hasVideoBeenPlayed(video) {
      if (!video) {
        return false;
      }
      // get value from local storage each time because it might have changed.
      const videosWatched = JSON.parse(localStorage.getItem("videosWatched"));
      return videosWatched && videosWatched.includes(video);
    },
    youtubeFinished() {
      // if the room has an auto_give_key
      // give it to them.
      if (this.currentRoom.auto_give_key) {
        this.$store.dispatch("giveKeyToUser", {
          key_id: this.currentRoom.auto_give_key,
          user_id: this.currentUser.user_id,
          room_id: this.currentRoom.room_id,
        });
      }

      setTimeout(() => {
        this.youtubePlayed = true;
      }, 3000);
    },
    replayVideo() {
      this.youtubePlayed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.RoomMain {
  @media (min-width: $bp-min-lg) {
    height: 100%;
    &_Row {
      height: 100%;
    }
  }
  &_VideoArea {
    position: relative;
    flex-grow: 1;
  }
  &_Inner {
    flex-grow: 1;
  }
  &_Col {
    // max-width: 100%;
    transition: max-width 0.3s;
  }
  &_Loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
}
</style>
